import { useTranslation } from "react-i18next";
import styles from "./Error.module.css";

const Error = () => {
  const { t } = useTranslation("common");

  return (
    <article className={styles.container}>
      <h2 className={styles.text}>{t("pageLoadingError")}</h2>
    </article>
  );
};

export default Error;
