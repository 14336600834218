import { useMutation, useQueryClient } from "react-query";
import yearPlanAPI from "../../../api/yearPlanAPI";

export const useSaveYearPlanAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (props) => yearPlanAPI.savePlanAccess(props.data, props.id),
    {
      onSuccess: () => queryClient.invalidateQueries("yearPlans"),
      onError: (err) => console.log("share access update error: ", err),
    }
  );
};
