import { createContext } from "react";
import { useQueryClient } from "react-query";
import { io } from "socket.io-client";
import { applyPatch } from "fast-json-patch";
import { toast } from "react-hot-toast";
import {
  SOCKET_BASE_URL,
  SOCKET_HOSTNAME_URL,
  SOCKET_PATH,
} from "../../constants";

const WebSocketContext = createContext(null);

export { WebSocketContext };

const Websocket = ({ children }) => {
  let socket,
    ws,
    last_token,
    last_planId,
    last_isEditingState = false;

  const queryClient = useQueryClient();

  const init = (token) => {
    last_token = token;

    if (!socket) {
      console.log("Connection socket", SOCKET_BASE_URL);

      socket = io(SOCKET_HOSTNAME_URL, {
        path: SOCKET_PATH,
        query: { token },
      });

      socket.onAny((event, ...args) =>
        console.log("socket event: ", event, args)
      );

      socket.on("message", (payload) => {
        console.log("Received socket message: ", payload);

        switch (payload.key) {
          case "PLAN":
            if (payload.data && payload.data.length) {
              queryClient.setQueryData(["plans", payload.id], (oldData) => {
                const updatedData = applyPatch(
                  oldData,
                  payload.data,
                  false,
                  false
                ).newDocument;

                return updatedData;
              });
            }
            break;
          default:
            return;
        }
      });

      socket.on("room", (payload) => {
        console.log("Received room update: ", payload);

        const roomId = `${payload.key}:${payload.id}`;
        if (payload.editorsInRoom?.length > 1) {
          toast("", {
            id: roomId,
          });
        } else {
          toast.remove(roomId);
        }
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket reconnect_attempt");
        socket.query = {
          token: last_token,
        };
        socket.io.opts.query = {
          token: last_token,
        };
      });

      socket.on("connect", () => {
        console.log("Socket connected");

        if (!socket.recovered && last_planId) {
          addSubjectPlanListener(last_planId, last_isEditingState);
        }
      });

      socket.on("disconnect", () => {
        last_planId = null;
        console.log("Socket disconnected");
      });

      socket.on("reconnect", () => {
        console.log("Socket reconnected");
      });
    }
  };

  const addSubjectPlanListener = (planId, isEditing) => {
    last_planId = planId;
    last_isEditingState = isEditing;

    socket.emit("add_key", {
      key: "PLAN",
      id: planId,
      token: `${last_token}`,
      isEditing,
    });
  };

  const removeSubjectPlanListener = (planId) => {
    last_planId = null;
    last_isEditingState = false;
    socket.emit("remove_key", { key: "PLAN", id: planId });
    toast.remove(`PLAN:${planId}`);
  };

  const getSocket = () => {
    return socket;
  };

  ws = {
    init,
    addSubjectPlanListener,
    removeSubjectPlanListener,
    getSocket,
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default Websocket;
