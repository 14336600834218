import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAuthUserId } from "../../auth/AuthSlice";
import { useAllYearPlans } from "./useAllYearPlans";

export const useSharedYearPlans = () => {
  const userId = useSelector(selectAuthUserId);
  const query = useAllYearPlans();

  return {
    ...query,
    data: useMemo(
      () => query.data?.filter((item) => item.userId !== userId),
      [query.data, userId]
    ),
  };
};
