import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./TableItem.module.css";

import { selectAuthUserId } from "../../features/auth/AuthSlice";
import { useSaveSubjectPlan } from "../../features/subjectPlan/hooks/useSaveSubjectPlan.js";
import { useDeleteSubjectPlan } from "../../features/subjectPlan/hooks/useDeleteSubjectPlan.js";
import { calculateCompletionPercentage } from "../../common/functions";
import labels from "../../common/labels.json";

import {
  IconDuplicate,
  IconEye,
  IconShare,
  IconTrash,
} from "../../common/Icons/Icons.jsx";

const TableItem = ({
  data,
  showShareModal,
  freezeClassName,
  displaySharedOnly,
}) => {
  const history = useHistory();
  const { t } = useTranslation("subjectPlan");

  const planCompletionPercentage = calculateCompletionPercentage(data);

  const { mutate: savePlan } = useSaveSubjectPlan();
  const { mutate: deletePlan } = useDeleteSubjectPlan();
  const userId = useSelector(selectAuthUserId);

  const allExistingLabels = labels.map((group) => group.items).flat();

  function goToEditPlan(id) {
    history.push(`/temaplan/ny/${id}`);
  }

  function handleDeleteItem() {
    const decision = window.confirm(
      t("common:confirmations.removeSubjectPlan")
    );

    if (decision) deletePlan(data.id);
  }

  function handleDisplayItem() {
    history.push(`/temaplan/visning/${data.id}`);
    window.scrollTo({
      top: 0,
    });
  }

  function handleShareButtonClick() {
    showShareModal(data.id);
  }

  function handleDuplicatePlan() {
    const post = {
      title: `${data.title || t("common:unknown")} - kopi`,
      grade: data.grade || "",
      subject: data.subject || "",
      qualityMarkers: data.qualityMarkers || "",
      time: +data.time || 0,
      labels:
        // Filter out labels that no longer exist in the system
        data.labels
          ?.split(",")
          .filter((label) => allExistingLabels.includes(label))
          ?.join(",") || "",
      involvement: data.involvement || "",
      goals: data.goals || "",
      feedback: data.feedback || "",
      curriculumBase: data.curriculumBase || "",
      concepts: data.concepts || "",
      challenges: data.challenges || "",
      assessment: data.assessment || "",
      summary: data.summary || "",
      reason: data.reason || "",
      type: data.type || "",
      resources: data.resources || "",
      instructions: data.instructions || "",
      activities:
        data.activities?.map((activity, index) => ({
          type: activity.type,
          resources: activity.resources,
          instructions: activity.instructions,
          homework: activity.homework,
          title: activity.title,
          position: activity.position || index,
        })) || [],
    };

    savePlan(post, {
      onSuccess: (response) => {
        if (response?.data?.id) goToEditPlan(response.data?.id);
      },
    });
  }

  function hasWriteAccessTo(planData) {
    if (userId === planData.userId) return true;

    const myAccess = planData.access?.find(
      (access) => access.userId === userId
    );

    return myAccess?.access === "W" ? true : false;
  }

  return (
    <tr className={styles.row}>
      <td className={freezeClassName}>
        <NavLink
          to={
            hasWriteAccessTo(data)
              ? `/temaplan/rediger/${data.id}`
              : `/temaplan/visning/${data.id}`
          }
        >
          <span
            role="img"
            aria-label="fylt ut prosent"
            className={styles.percentageIcon}
            style={{
              "--percentage": planCompletionPercentage,
            }}
            title={`${Math.round(planCompletionPercentage * 100)}% fylt ut`}
          />

          {data.title || " "}
        </NavLink>
      </td>

      <td className={styles.freezeSubject}>
        {data.subject.split(";").map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        )) || " "}
      </td>

      <td>
        {data.grade
          ? data.grade.split(";").map((item, index) => (
              <span key={`grade-${index}`}>
                {item}. trinn <br />
              </span>
            ))
          : null}
      </td>

      <td className={styles.labels}>
        {data.labels
          ? data.labels
              .split(",")
              ?.filter((label) => allExistingLabels.includes(label))
              ?.map((label) => t(`subjectPlan:labelTags.tags.${label}`))
              .join(", ")
          : null}
      </td>

      <td>{new Date(data.updatedAt).toLocaleDateString("en-GB")}</td>

      <td className={styles.icons}>
        <button title="Åpne" onClick={handleDisplayItem}>
          <IconEye />
        </button>

        {displaySharedOnly ? null : (
          <button title="Del" onClick={handleShareButtonClick}>
            <IconShare />
          </button>
        )}

        <button title="Dupliser" onClick={handleDuplicatePlan}>
          <IconDuplicate />
        </button>

        {displaySharedOnly ? null : (
          <button title="Slett" onClick={handleDeleteItem}>
            <IconTrash />
          </button>
        )}
      </td>
    </tr>
  );
};

export default TableItem;
