import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const ExpandingTextArea = ({ name, id, minHeight, defaultValue, ...props }) => {
  const { register, watch } = useFormContext();

  const value = watch(name);

  // Resize element to fit it's content on load and every value change
  useEffect(() => {
    const element = window.document.getElementById(id);
    resizeToFitContent(element);
  }, [id, value]);

  function resizeToFitContent(element) {
    element.style.height = "auto";
    element.style.height = `calc(0.4rem + ${element.scrollHeight}px)`;
  }

  return (
    <textarea
      id={id}
      {...register(name)}
      style={{ minHeight: (minHeight || 2) + "em" }}
      defaultValue={defaultValue || null}
      spellCheck="false"
      {...props}
    />
  );
};

export default ExpandingTextArea;
