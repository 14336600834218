import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./EditPlan.module.css";

import { useSubjectPlan } from "../../features/subjectPlan/hooks/useSubjectPlan";
import { useSubjectPlanListener } from "../../features/subjectPlan/hooks/useSubjectPlanListener";

import Summary from "./Editor/Summary";
import Editor from "./Editor/Editor";
import Footer from "../_shared/Footer/Footer";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";
import ShareModal from "../_shared/Modals/ShareModal";

const EditPlan = () => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const params = useParams();
  const planId = Number(params.id);
  const { data: plan, isLoading, isError } = useSubjectPlan(planId);

  // Start listening to changes on plan made by other users
  useSubjectPlanListener(planId);

  // Update background and navigation colors on body element when on this page
  useEffect(() => {
    document.body.classList.add(styles.nav__variable);
    document.body.classList.add(styles.background);

    return () => {
      document.body.classList.remove(styles.nav__variable);
      document.body.classList.remove(styles.background);
    };
  }, []);

  function handleSharePlan() {
    setIsShareModalOpen(true);
  }

  return (
    <>
      <main className={styles.main}>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Error />
        ) : plan ? (
          <>
            <Summary planId={planId} sharePlan={handleSharePlan} />
            <Editor planId={planId} />
          </>
        ) : null}
      </main>

      {isShareModalOpen && (
        <ShareModal
          closeModal={() => setIsShareModalOpen(false)}
          planId={planId}
          planType="subject"
        />
      )}

      <Footer />
    </>
  );
};

export default EditPlan;
