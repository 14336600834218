import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styles from "./NavBar.module.css";

import { doLogout } from "../../../features/auth/AuthSlice";
import { useMyUser } from "../../../features/user/hooks/useMyUser";

import { ChevronDown, FiX } from "../../../common/Icons/Icons.jsx";
import { usePatchUser } from "../../../features/user/hooks/usePatchUser";

const NavBar = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useMyUser();

  const { mutate: patchUser } = usePatchUser();

  useEffect(() => {
    setIsHamburgerMenuOpen(false);
    setIsUserMenuOpen(false);
  }, [location]);

  function toggleHamburgerMenu() {
    setIsHamburgerMenuOpen((prev) => !prev);
  }

  function handleLogOutClick() {
    dispatch(doLogout());
  }

  function changeWebsiteLanguage(lang) {
    i18n.changeLanguage(lang);
    patchUser({ language: lang });
  }

  return (
    <header className={styles.container}>
      <NavLink to="/framside" exact>
        <h1 className={styles.logo}>DigUp</h1>
      </NavLink>

      <button
        className={`${styles.menuButton} ${styles.hamburger}`}
        title="Åpne meny"
        onClick={toggleHamburgerMenu}
      >
        <span className={styles.line}></span>
        <span className={styles.line}></span>
        <span className={styles.line}></span>
        <span className={styles.name}>Meny</span>
      </button>

      <nav
        className={`${styles.nav} ${isHamburgerMenuOpen && styles.inHamburger}`}
      >
        <div
          className={styles.backdrop}
          aria-hidden="true"
          onClick={toggleHamburgerMenu}
        ></div>

        <section className={styles.hamburger__header}>
          <h1 className={styles.logo}>DigUp</h1>
          <button
            title="lukk meny"
            onClick={toggleHamburgerMenu}
            styles={styles.closeButton}
          >
            <FiX />
            <span className={styles.text}>Lukk</span>
          </button>
        </section>

        <ul className={styles.list}>
          <li>
            <NavLink to="/framside" exact activeClassName={styles.activeLink}>
              {t("mainPage")}
            </NavLink>
          </li>

          <li>
            <NavLink to="/arsplanar" activeClassName={styles.activeLink}>
              {t("yearPlans")}
            </NavLink>
          </li>

          <li>
            <NavLink to="/temaplanar" activeClassName={styles.activeLink}>
              {t("subjectPlans")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/verktoykasse" activeClassName={styles.activeLink}>
              {t("toolbox")}
            </NavLink>
          </li>
        </ul>

        <section className={styles.user}>
          <button
            onClick={() => setIsUserMenuOpen((prev) => !prev)}
            title="Åpne meny"
          >
            {user?.data?.fullName}
            <ChevronDown className={styles.dropdownArrow} />
          </button>

          <section
            className={`${styles.dropdown} ${isUserMenuOpen && styles.open}`}
          >
            <ul>
              <li>
                <button
                  onClick={() => changeWebsiteLanguage("nn_NO")}
                  className={
                    i18n.language === "nn_NO" ? styles.activeLanguage : ""
                  }
                >
                  nynorsk
                </button>
              </li>
              <li>
                <button
                  onClick={() => changeWebsiteLanguage("nb_NO")}
                  className={
                    i18n.language === "nb_NO" ? styles.activeLanguage : ""
                  }
                >
                  bokmål
                </button>
              </li>
              <li>
                <button onClick={handleLogOutClick}>logg ut</button>
              </li>
            </ul>
          </section>
        </section>
      </nav>
    </header>
  );
};

export default NavBar;
