import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import WebSocketProvider from "./features/socket/SocketContext";
import "./index.css";
import "./i18n.js";
import App from "./App";
import store from "./app/store";
import Spinner from "./components/_shared/Spinner/Spinner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <QueryClientProvider client={queryClient}>
            <WebSocketProvider>
              <App />
            </WebSocketProvider>
          </QueryClientProvider>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
