import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";
import FontRegular from "../../../common/Fonts/EncodeSansSemiCondensed-Regular.ttf";
import FontBold from "../../../common/Fonts/EncodeSansSemiCondensed-SemiBold.ttf";
import { useTranslation } from "react-i18next";
import labels from "../../../common/labels.json";

// Register custom font
Font.register({
  family: "Encode",
  fonts: [
    { src: FontRegular, fontWeight: 400 },
    // { src: FontRegular, fontWeight: 400, fontStyle: "italic" },
    { src: FontBold, fontWeight: 600 },
    // { src: FontBold, fontWeight: 600, fontStyle: "italic" },
  ],
});

// Register online source to handle emojis
Font.registerEmojiSource({
  format: "png",
  url: "https://twemoji.maxcdn.com/2/72x72/",
});

// Define all styles for the pdf renderer
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#f0faf1",
    fontFamily: "Encode",
    fontSize: 10,
    lineHeight: 1.5,
    padding: 30,
    paddingBottom: 10,
  },
  summary: {
    padding: 30,
    width: "100vw",
    transform: "translate(-30, -30)",
    backgroundColor: "#b3e6b8",
    paddingRight: 120,
  },
  logo: { fontSize: 12, fontWeight: "bold", marginBottom: 12 },
  header: { color: "#000000", fontSize: 24 },
  grade: {
    marginBottom: 3,
  },
  subject: {
    marginRight: 32,
    fontSize: 12,
    fontWeight: "bold",
  },
  time: { marginTop: 3 },
  createdBy: { marginBottom: 12 },
  label: {
    fontWeight: "bold",
    fontSize: 12,
  },
  description: {
    marginBottom: 12,
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 6,
  },
  activities__container: {
    marginBottom: 24,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  activity_startPhase: {
    backgroundColor: "#f8e16c",
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    overflow: "hidden",
  },
  activity_researchPhase: {
    backgroundColor: "#ffc2b4",
    padding: "20px",
    width: "100%",
    marginBottom: "10px",
    overflow: "hidden",
  },
  activity__title: {
    fontWeight: "bold",
    fontSize: 12,
    marginBottom: "0px",
  },
  activity__label: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "8px",
  },
  labelTags__container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 12,
  },
  labelTag: {
    marginRight: 6,
    padding: "3 6",
    marginBottom: 6,
    backgroundColor: "#b3e6b8",
  },
  link: {
    color: "#00f",
    textDecoration: "none",
  },
});

const RenderPDF = (plan) => {
  const { t } = useTranslation("subjectPlan");

  const allExistingLabels = labels.map((group) => group.items).flat();

  function containsAnyAssessmentContent(subjectPlan) {
    return (
      subjectPlan?.labels?.length > 0 ||
      subjectPlan?.assessment?.length > 0 ||
      subjectPlan?.involvement?.length > 0 ||
      subjectPlan?.qualityMarkers?.length > 0 ||
      subjectPlan?.feedback?.length > 0
    );
  }

  function hasActivitiesWithContent(data) {
    if (
      data.length !== 1 ||
      data[0].type !== "" ||
      data[0].resources !== "" ||
      data[0].instructions !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  const convertTextToLinks = (text) => {
    const urlCheck =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/i;

    const separatedWords = text.replace(/\n/g, " \n ").split(" ");
    let textArray = [];

    separatedWords.forEach((word, index) => {
      const lastIndex = textArray.length - 1;
      const lastAddedItem = textArray[lastIndex];

      if (word.match(urlCheck)) {
        if (typeof lastAddedItem === "string" && lastAddedItem !== "\n") {
          textArray[lastIndex] += "\n";
        }

        const endsWithComa = (string) =>
          string.charAt(string.length - 1) === ",";

        const url = endsWithComa(word) ? word.slice(0, -1) : word.slice();

        const shortUrl = url.length > 45 ? `${url.slice(0, 45)}...` : url;

        const link = (
          <Text key={`${url}${index}`} style={styles.link}>
            <Link src={url}>{shortUrl}</Link>
          </Text>
        );

        textArray.push(link);
        if (endsWithComa(word)) textArray.push(",");
      } else if (typeof lastAddedItem === "string") {
        textArray[lastIndex] += ` ${word}`;
      } else {
        textArray.push(`${index > 0 && word !== "\n" ? " " : ""}${word}`);
      }
    });

    return textArray;
  };

  function parseRichText(content) {
    const parsedJSONValue = tryParsingJSON(content) || [
      { type: "paragraph", children: [{ text: content || "" }] },
    ];

    const elements = parsedJSONValue.map((group, groupIndex) => (
      <Text style={styles.description} key={`group-${groupIndex}`}>
        {group.children?.length
          ? group.children.map((child, childIndex) => (
              <Text
                key={`child-${groupIndex}-${childIndex}`}
                style={{
                  fontWeight: child.bold ? "bold" : "normal",
                  // fontStyle: child.italic ? "italic" : "normal",
                }}
              >
                {child.text || " "}
              </Text>
            ))
          : null}
      </Text>
    ));

    return elements;

    function tryParsingJSON(string) {
      try {
        const parsed = JSON.parse(string);
        if (parsed && typeof parsed === "object") return parsed;
      } catch (e) {}
      return false;
    }
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.summary}>
          <Text style={styles.logo}>DigUp</Text>

          <Text style={styles.header}>{`${plan?.title || ""}`}</Text>

          {plan?.grade ? (
            <Text style={styles.grade}>
              {plan.grade.split(";").join(". trinn, ")}.trinn
            </Text>
          ) : null}

          {plan?.subject ? (
            <Text style={styles.subject}>{`${
              plan?.subject.replace(/;/g, "\n") || ""
            }`}</Text>
          ) : null}

          {plan?.time && Number(plan.time) > 0 ? (
            <Text style={styles.time}>
              {`${t("labels.time")}: ${plan.time} ${t("general.sessions")}`}
            </Text>
          ) : null}

          {plan?.user?.fullName ? (
            <Text style={styles.createdBy}>
              {`${t("general.createdBy")}: ${plan.user.fullName}`}
            </Text>
          ) : null}

          {plan?.concepts?.length > 0 ? (
            <>
              <Text style={styles.label}>{t("labels.concepts-short")}</Text>
              <Text style={styles.description}>{`${plan.concepts || ""}`}</Text>
            </>
          ) : null}

          {plan?.challenges?.length > 0 ? (
            <>
              <Text style={styles.label}>{t("labels.challenges-short")}</Text>
              <Text style={styles.description}>{`${
                plan.challenges || ""
              }`}</Text>
            </>
          ) : null}

          {plan?.goals?.length > 0 ? (
            <>
              <Text style={styles.label}>{t("labels.goals")}</Text>
              <Text style={styles.description}>{`${plan.goals || ""}`}</Text>
            </>
          ) : null}

          {plan?.curriculumBase?.length > 0 ? (
            <>
              <Text style={styles.label}>{t("labels.curriculumBase")}</Text>
              {parseRichText(plan.curriculumBase)}
            </>
          ) : null}

          {plan?.reason?.length > 0 ? (
            <>
              <Text style={styles.label}>{t("labels.reason")}</Text>
              <Text style={styles.description}>{`${plan.reason || ""}`}</Text>
            </>
          ) : null}
        </View>

        {plan?.type || plan?.instructions || plan?.resources ? (
          <View wrap={false}>
            <Text style={styles.subHeader}>
              {t("labels.startPhase-header")}
            </Text>

            <View style={styles.activities__container}>
              <View style={styles.activity_startPhase}>
                {!!plan.instructions && (
                  <Text>
                    <Text style={styles.activity__label}>
                      {t("labels.startPhase-knowledge")}{" "}
                    </Text>
                    <Text>{`${plan.instructions}`}</Text>
                  </Text>
                )}

                {!!plan.type && (
                  <Text>
                    <Text style={styles.activity__label}>
                      {t("labels.activity")}{" "}
                    </Text>
                    <Text>{`${plan.type}`}</Text>
                  </Text>
                )}

                {!!plan.resources && (
                  <Text>
                    <Text style={styles.activity__label}>
                      {t("labels.activity-resources-short")}{" "}
                    </Text>
                    <Text>{convertTextToLinks(plan.resources)}</Text>
                  </Text>
                )}
              </View>
            </View>
          </View>
        ) : null}

        {plan?.activities?.length > 0 &&
        hasActivitiesWithContent(plan.activities) ? (
          <View>
            <Text style={styles.subHeader}>{t("general.researchPhase")}</Text>

            <View style={styles.activities__container}>
              {plan.activities.map((item, index) => (
                <View style={styles.activity_researchPhase} key={index}>
                  <Text style={styles.activity__title}>
                    {`${t("general.session")} ${index + 1}`}
                  </Text>

                  {!!item.title && (
                    <Text>
                      <Text style={styles.activity__label}>
                        {t("labels.activity-title")}{" "}
                      </Text>
                      <Text>{`${item.title || "–"}`}</Text>
                    </Text>
                  )}

                  {!!item.type && (
                    <Text>
                      <Text style={styles.activity__label}>
                        {t("labels.activity-type-short")}{" "}
                      </Text>
                      <Text>{`${item.type || "–"}`}</Text>
                    </Text>
                  )}

                  {!!item.resources && (
                    <Text>
                      <Text style={styles.activity__label}>
                        {t("labels.activity-resources-short")}{" "}
                      </Text>
                      <Text>{convertTextToLinks(item.resources || "–")}</Text>
                    </Text>
                  )}

                  {!!item.instructions && (
                    <Text>
                      <Text style={styles.activity__label}>
                        {t("labels.activity-instructions")}{" "}
                      </Text>
                      <Text>{`${item.instructions || "–"}`}</Text>
                    </Text>
                  )}

                  {!!item.homework && (
                    <Text>
                      <Text style={styles.activity__label}>
                        {t("labels.activity-homework")}{" "}
                      </Text>
                      <Text>{`${item.homework || "–"}`}</Text>
                    </Text>
                  )}
                </View>
              ))}
            </View>
          </View>
        ) : null}

        {containsAnyAssessmentContent(plan) ? (
          <Text style={styles.subHeader}>{t("general.assessment")}</Text>
        ) : null}

        {plan?.assessment?.length > 0 ? (
          <View wrap={false}>
            <Text style={styles.label}>{t("labels.assessment-sub-short")}</Text>
            <Text style={styles.description}>{`${plan.assessment || ""}`}</Text>
          </View>
        ) : null}

        {plan?.involvement?.length > 0 ? (
          <View wrap={false}>
            <Text style={styles.label}>{t("labels.involvement")}</Text>
            <Text style={styles.description}>{`${
              plan.involvement || ""
            }`}</Text>
          </View>
        ) : null}

        {plan?.qualityMarkers?.length > 0 ? (
          <View wrap={false}>
            <Text style={styles.label}>{t("labels.qualityMarkers")}</Text>
            <Text style={styles.description}>{`${
              plan.qualityMarkers || ""
            }`}</Text>
          </View>
        ) : null}

        {plan?.feedback?.length > 0 ? (
          <View wrap={false}>
            <Text style={styles.label}>{t("labels.feedback")}</Text>
            <Text style={styles.description}>{`${plan.feedback || ""}`}</Text>
          </View>
        ) : null}

        {plan?.labels?.length > 0 ? (
          <View wrap={false}>
            <Text style={styles.label}>{t("labels.tags-short")}</Text>

            <View style={styles.labelTags__container} wrap={false}>
              {plan.labels
                ?.split(",")
                .filter((label) => allExistingLabels.includes(label))
                ?.map((label, index) => (
                  <Text style={styles.labelTag} key={index}>
                    {t(`labelTags.tags.${label}`)}
                  </Text>
                ))}
            </View>
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default RenderPDF;
