import { updateData, fetchData, deleteData } from "./fetch";

const fetchYearPlans = async () => fetchData(`core/yearplan`);
const fetchYearPlan = async (id) => fetchData(`core/yearplan/${id}`);
const updateYearPlan = async (data) => updateData(data, `core/yearplan`);
const deleteYearPlan = async (id) => deleteData(`core/yearplan/${id}`);

const updateSubjectPlanInYearPlan = async (data) =>
  updateData(data, `core/yearplan/plan`);

const deleteSubjectPlanInYearPlan = async (id) =>
  deleteData(`core/yearplan/plan/${id}`);

const savePlanAccess = async (data, planId) =>
  updateData(data, `core/yearplan/share/${planId}`);

const updatePlanAccess = async (data) =>
  updateData(data, `core/yearplan/share`);

const deletePlanAccess = async (accessId) =>
  deleteData(`core/yearplan/share/${accessId}`);

const takePlanAccess = async (accessToken) =>
  fetchData(`core/yearplan/share/${accessToken}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchYearPlans,
  fetchYearPlan,
  updateYearPlan,
  deleteYearPlan,
  updateSubjectPlanInYearPlan,
  deleteSubjectPlanInYearPlan,
  savePlanAccess,
  updatePlanAccess,
  deletePlanAccess,
  takePlanAccess,
};
