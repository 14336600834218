import { useTranslation } from "react-i18next";
import styles from "./NotFound.module.css";

import Footer from "../Footer/Footer";

const NotFound = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <main className={styles.container}>
        <h2 className={styles.text}>{t("pageNotFound")}</h2>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;
