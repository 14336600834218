import { useState } from "react";
import { endOfDay, startOfDay, endOfMonth } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./YearPlan.module.css";

import { useYearPlan } from "../../features/yearPlan/hooks/useYearPlan";
import { selectAuthUserId } from "../../features/auth/AuthSlice";

import { IconShare, IconEdit } from "../../common/Icons/Icons";
import Footer from "../_shared/Footer/Footer";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";
import YearCalendar from "./YearCalendar/YearCalendar";
import EditYearPlanModal from "../_shared/Modals/EditYearPlanModal";
import EditSubjectPlanInYearPlanModal from "../_shared/Modals/EditSubjectPlanInYearPlanModal";
import ShareModal from "../_shared/Modals/ShareModal";

const YearPlan = () => {
  const [newSubjectPlanFromDate, setNewSubjectPlanFromDate] = useState(null);
  const [selectedSubjectPlanId, setSelectedSubjectPlanId] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isEditYearPlanModalOpen, setIsEditYearPlanModalOpen] = useState(false);
  const [isEditSubjectPlanModalOpen, setIsEditSubjectPlanModalOpen] =
    useState(false);

  const { t } = useTranslation("subjectPlan");
  const params = useParams();
  const planId = Number(params.id);
  const userId = useSelector(selectAuthUserId);

  const { data: plan, isLoading, isError } = useYearPlan(planId);

  function getYearPlanWithIntervals(data) {
    if (data?.plans && data.plans.length > 0) {
      const planWithIntervals = {
        ...data,

        plans: data.plans
          .filter((item) => item.plan)
          ?.map((plan) => ({
            ...plan,
            interval: {
              start: startOfDay(new Date(plan.fromDate)),
              end: endOfDay(new Date(plan.toDate)),
            },
          })),
      };

      return planWithIntervals;
    } else {
      return data;
    }
  }

  const yearInterval = {
    start: new Date(plan?.year, 7),
    end: endOfMonth(new Date(plan?.year + 1, 6)),
  };

  function handleSharePlan() {
    setIsShareModalOpen(true);
  }

  function handleEditYearPlan() {
    setIsEditSubjectPlanModalOpen(false);
    setIsEditYearPlanModalOpen(true);
  }

  function handleAddSubjectPlan(fromDate) {
    if (fromDate) setNewSubjectPlanFromDate(fromDate);

    setIsEditYearPlanModalOpen(false);
    setIsEditSubjectPlanModalOpen(true);
  }

  function handleEditSubjectPlan(planId) {
    if (planId) setSelectedSubjectPlanId(planId);

    setIsEditYearPlanModalOpen(false);
    setIsEditSubjectPlanModalOpen(true);
  }

  function closeSubjectPlanModal() {
    setIsEditSubjectPlanModalOpen(false);
    setNewSubjectPlanFromDate(null);
    setSelectedSubjectPlanId(null);
  }

  function closeYearPlanModal() {
    setIsEditYearPlanModalOpen(false);
  }

  return (
    <>
      <main>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Error />
        ) : (
          <section className={styles.container}>
            <h1 className={styles.title}>{plan.title}</h1>

            <section className={styles.subtitles}>
              <h2>
                {t("common:theSchoolYear")} {yearInterval.start.getFullYear()} -{" "}
                {yearInterval.end.getFullYear()}{" "}
              </h2>
              <h2>{plan.subject}</h2>
              <h2>{`${plan.grade}. trinn`}</h2>
            </section>

            <section className={styles.optionButtons}>
              {plan.userId === userId && (
                <button onClick={handleSharePlan}>
                  <IconShare />
                  <span>{t("general.sharePlan")}</span>
                </button>
              )}

              {plan.userId !== userId &&
              plan.access.find((item) => item.userId === userId)?.access ===
                "R" ? null : (
                <button onClick={handleEditYearPlan}>
                  <IconEdit />
                  <span>{t("general.edit")}</span>
                </button>
              )}
            </section>

            {plan.userId !== userId &&
            plan.access.find((item) => item.userId === userId)?.access ===
              "R" ? null : (
              <section className={styles.addPlanButton}>
                <button onClick={() => handleAddSubjectPlan()}>
                  + {t("common:addSubjectPlanToYearPlan")}
                </button>
              </section>
            )}

            <YearCalendar
              yearPlan={getYearPlanWithIntervals(plan)}
              addSubjectPlan={handleAddSubjectPlan}
              editSubjectPlan={handleEditSubjectPlan}
            />
          </section>
        )}

        {isShareModalOpen && (
          <ShareModal
            closeModal={() => setIsShareModalOpen(false)}
            planId={planId}
            planType="year"
          />
        )}

        {isEditYearPlanModalOpen && (
          <EditYearPlanModal planId={planId} closeModal={closeYearPlanModal} />
        )}

        {isEditSubjectPlanModalOpen && (
          <EditSubjectPlanInYearPlanModal
            yearPlan={plan}
            selectedDate={newSubjectPlanFromDate}
            planId={selectedSubjectPlanId}
            closeModal={closeSubjectPlanModal}
          />
        )}
      </main>
      <Footer />
    </>
  );
};

export default YearPlan;
