import { useState } from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./SubjectPlans.module.css";

import { useSharedSubjectPlans } from "../../features/subjectPlan/hooks/useSharedSubjectPlans";
import { useMySubjectPlans } from "../../features/subjectPlan/hooks/useMySubjectPlans";

import Table from "./Table";
import ShareModal from "../_shared/Modals/ShareModal";
import Footer from "../_shared/Footer/Footer";

const SubjectPlans = () => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const myPlansData = useMySubjectPlans();
  const sharedPlansData = useSharedSubjectPlans();

  function handleAddPlan() {
    history.push(`/temaplan/ny`);
    window.scrollTo({
      top: 0,
    });
  }

  function handleShowShareModal(planId) {
    setSelectedPlanId(planId);
    setIsShareModalOpen(true);
  }

  return (
    <>
      <main className={styles.main}>
        <section className={styles.header}>
          <h1>{t("subjectPlans")}</h1>

          <button className={styles.addPlan} onClick={handleAddPlan}>
            {t("makeNewPlan")}
          </button>
        </section>

        <nav className={styles.nav}>
          <NavLink to="/temaplanar" exact activeClassName={styles.activeLink}>
            {t("plans-my")}
          </NavLink>

          <NavLink
            to="/temaplanar/delt-med-meg"
            exact
            activeClassName={styles.activeLink}
          >
            {t("plans-shared")}
          </NavLink>
        </nav>

        <Switch>
          <Route path="/temaplanar/delt-med-meg">
            <Table
              plansData={sharedPlansData}
              showShareModal={handleShowShareModal}
              displaySharedOnly
            />
          </Route>

          <Route path="/temaplanar">
            <Table
              plansData={myPlansData}
              showShareModal={handleShowShareModal}
            />
          </Route>
        </Switch>

        {isShareModalOpen && (
          <ShareModal
            closeModal={() => setIsShareModalOpen(false)}
            planId={selectedPlanId}
            planType="subject"
          />
        )}
      </main>
      <Footer />
    </>
  );
};

export default SubjectPlans;
