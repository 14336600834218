const initialPlanKeys = [
  "title",
  "grade",
  "subject",
  "qualityMarkers",
  "time",
  "labels",
  "involvement",
  "goals",
  "feedback",
  "curriculumBase",
  "concepts",
  "challenges",
  "assessment",
  "summary",
  "reason",
  "type",
  "resources",
  "instructions",
  "activities",
];

export const calculateCompletionPercentage = (planData) => {
  let completionCount = 0;

  for (let i = 0; i < initialPlanKeys.length; i++) {
    if (
      planData[initialPlanKeys[i]] === undefined ||
      planData[initialPlanKeys[i]] === "" ||
      planData[initialPlanKeys[i]] === 0 ||
      planData[initialPlanKeys[i]]?.length === 0
    ) {
      continue;
    } else {
      completionCount++;
    }
  }

  const completionPercentage = completionCount / initialPlanKeys.length;

  return completionPercentage;
};

export const convertTextToLinks = (text) => {
  const urlCheck =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/i;

  const separatedWords = text.replace(/\n/g, " \n ").split(" ");
  let textArray = [];

  separatedWords.forEach((word, index) => {
    const lastIndex = textArray.length - 1;
    const lastAddedItem = textArray[lastIndex];

    if (word.match(urlCheck)) {
      if (typeof lastAddedItem === "string" && lastAddedItem !== "\n")
        textArray[lastIndex] += " ";

      const endsWithComa = (string) => string.charAt(string.length - 1) === ",";

      const url = endsWithComa(word) ? word.slice(0, -1) : word.slice();

      const urlWithPrefix =
        url.slice(0, 7) === "http://" || url.slice(0, 8) === "https://"
          ? url
          : `https://${url}`;

      const link = (
        <a
          href={urlWithPrefix}
          target="_blank"
          rel="noopener noreferrer"
          key={`${url}-${index}`}
        >
          {url}
        </a>
      );

      textArray.push(link);
      if (endsWithComa(word)) textArray.push(",");
    } else if (typeof lastAddedItem === "string") {
      textArray[lastIndex] += ` ${word}`;
    } else {
      textArray.push(`${index > 0 && word !== "\n" ? " " : ""}${word}`);
    }
  });

  return textArray;
};
