import { useCallback, useMemo } from "react";
import { Slate, Editable, useSlate } from "slate-react";
import { Editor, Transforms, Text } from "slate";
import { useFormContext } from "react-hook-form";
import styles from "./RichTextArea.module.css";

const CustomEditor = {
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.bold === true,
      universal: true,
    });

    return !!match;
  },

  toggleBoldMark(editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true }
    );
  },
};

const RichTextArea = ({ minHeight, name, editor }) => {
  const { watch, setValue } = useFormContext();

  const formValue = watch(name);

  const value = useMemo(() => {
    const parsedJSONValue = tryParsingJSON(formValue);

    return (
      parsedJSONValue || [
        {
          type: "paragraph",
          children: [{ text: formValue || "" }],
        },
      ]
    );
  }, [formValue]);

  function tryParsingJSON(string) {
    try {
      const parsed = JSON.parse(string);
      if (parsed && typeof parsed === "object") return parsed;
    } catch (e) {}
    return false;
  }

  editor.children = value;

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  function handleEditorChange(newValue) {
    const isContentChange = editor.operations.some(
      (op) => "set_selection" !== op.type
    );

    if (isContentChange) {
      setValue(name, JSON.stringify(newValue), { shouldDirty: true });
    }
  }

  return (
    <article
      className={styles.mainContainer}
      style={{ minHeight: (minHeight || 10) + "em" }}
    >
      <Slate editor={editor} value={value} onChange={handleEditorChange}>
        <section className={styles.toolbarContainer}>
          <MarkButton format="bold" />
        </section>

        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck="false"
          onKeyDown={(e) => {
            if (e.metaKey && e.key === "b") {
              e.preventDefault();

              CustomEditor.toggleBoldMark(editor);
            }
          }}
        />
      </Slate>
    </article>
  );
};

export default RichTextArea;

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const MarkButton = ({ format }) => {
  const editor = useSlate();

  switch (format) {
    case "bold":
      return (
        <button
          type="button"
          className={`${styles.boldButton} ${
            isMarkActive(editor, "bold") ? styles.active : ""
          }`}
          title={"Slå feit tekst av og på"}
          onMouseDown={(e) => {
            e.preventDefault();
            CustomEditor.toggleBoldMark(editor);
          }}
        >
          B
        </button>
      );
    default:
      return null;
  }
};

const Leaf = (props) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? "bold" : "normal",
      }}
    >
      {props.children}
    </span>
  );
};

const DefaultElement = (props) => {
  return <p {...props.attributes}>{props.children}</p>;
};
