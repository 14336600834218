import { useMutation, useQueryClient } from "react-query";
import yearPlanAPI from "../../../api/yearPlanAPI";

export const useDeleteSubjectPlanInYearPlan = () => {
  const queryClient = useQueryClient();

  return useMutation(yearPlanAPI.deleteSubjectPlanInYearPlan, {
    onSuccess: () => queryClient.invalidateQueries("yearPlans"),
    onError: (error) => console.log("Delete error: ", error),
  });
};
