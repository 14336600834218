import { useMutation, useQueryClient } from "react-query";
import yearPlanAPI from "../../../api/yearPlanAPI";

export const useDeleteYearPlanAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(yearPlanAPI.deletePlanAccess, {
    onSuccess: () => queryClient.invalidateQueries("yearPlans"),
    onError: (error) => console.log("Delete acccess error: ", error),
  });
};
