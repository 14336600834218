import { useTranslation } from "react-i18next";
import styles from "./Overview.module.css";

import { useUpdateYearPlanAccess } from "../../../features/yearPlan/hooks/useUpdateYearPlanAccess";
import { useUpdateSubjectPlanAccess } from "../../../features/subjectPlan/hooks/useUpdateSubjectPlanAccess";
import { useDeleteYearPlanAccess } from "../../../features/yearPlan/hooks/useDeleteYearPlanAccess";
import { useDeleteSubjectPlanAccess } from "../../../features/subjectPlan/hooks/useDeleteSubjectPlanAccess";
import { ChevronDown, IconShare } from "../../../common/Icons/Icons";

const Overview = ({ planData, goToInviteView, closeModal, planType }) => {
  const { t } = useTranslation("common");

  const { mutate: updateYearPlanAccess, isLoading: isUpdatingYearPlanAccess } =
    useUpdateYearPlanAccess();
  const {
    mutate: updateSubjectPlanAccess,
    isLoading: isUpdatingSubjectPlanAccess,
  } = useUpdateSubjectPlanAccess();
  const { mutate: deleteYearPlanAccess, isLoading: isDeletingYearPlanAccess } =
    useDeleteYearPlanAccess();
  const {
    mutate: deleteSubjectPlanAccess,
    isLoading: isDeletingSubjectPlanAccess,
  } = useDeleteSubjectPlanAccess();

  const isAnythingLoading =
    isUpdatingYearPlanAccess ||
    isUpdatingSubjectPlanAccess ||
    isDeletingYearPlanAccess ||
    isDeletingSubjectPlanAccess;

  function handleShareClick() {
    goToInviteView();
  }

  function updateAccess(accessId, event) {
    const action = event.target.value;

    if (action === "D" && wantsToDeleteAccess()) {
      planType === "subject"
        ? deleteSubjectPlanAccess(accessId)
        : deleteYearPlanAccess(accessId);
    } else if (action === "W" || action === "R") {
      planType === "subject"
        ? updateSubjectPlanAccess({ access: action, id: accessId })
        : updateYearPlanAccess({ access: action, id: accessId });
    }
  }

  function wantsToDeleteAccess() {
    return window.confirm(t("confirmations.removeAccess"));
  }

  return (
    <>
      {planData && planData.access && planData.access.length > 0 ? (
        <>
          <p className={styles.header}> {t("usersWithAccessToPlan")}</p>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("email")}</th>
                <th>{t("access")}</th>
              </tr>
            </thead>
            <tbody>
              {planData.access.map((access, index) => (
                <tr key={`${access.id}-${index}`}>
                  <td>
                    {access.email}{" "}
                    {!access.userId && (
                      <span className={styles.pending}>{t("pending")}</span>
                    )}
                  </td>

                  <td>
                    <select
                      defaultValue={access.access}
                      className={styles.select}
                      onChange={(event) => updateAccess(access.id, event)}
                      disabled={isAnythingLoading}
                    >
                      <option value="W">{t("toEdit")}</option>
                      <option value="R">{t("toRead")}</option>
                      <option value="D">{t("remove")}</option>
                    </select>
                    <ChevronDown className={styles.dropdownArrow} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <p className={styles.header}>{t("planIsNotShared")}</p>
      )}

      <button
        className={`${styles.button} ${styles.share}`}
        onClick={handleShareClick}
        disabled={isAnythingLoading}
      >
        <IconShare />
        <span>{t("shareThePlan")}</span>
      </button>

      <button
        className={`${styles.button} ${styles.done}`}
        onClick={closeModal}
        disabled={isAnythingLoading}
      >
        {t("done")}
      </button>

      {isAnythingLoading && (
        <span className={styles.loading}>{t("updating")}</span>
      )}
    </>
  );
};

export default Overview;
