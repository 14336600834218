import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ShareModal.module.css";

import { useYearPlan } from "../../../features/yearPlan/hooks/useYearPlan";
import { useSubjectPlan } from "../../../features/subjectPlan/hooks/useSubjectPlan";
import { useBlockBodyScrollOnMount } from "../../../common/hooks/useBlockBodyScrollOnMount";

import Overview from "./Overview";
import Invite from "./Invite";
import Spinner from "../Spinner/Spinner";
import Error from "../Error/Error";

const ShareModal = ({ planId, closeModal, planType }) => {
  useBlockBodyScrollOnMount();
  const { t } = useTranslation("common");
  const [isInInviteView, setIsInIviteView] = useState(false);

  const {
    data: subjectPlan,
    isLoading: isSubjectPlanLoading,
    isError: isSubjectPlanError,
  } = useSubjectPlan(planType === "subject" ? planId : null);

  const {
    data: yearPlan,
    isLoading: isYearPlanLoading,
    isError: isYearPlanError,
  } = useYearPlan(planType === "year" ? planId : null);

  return (
    <article className={styles.container}>
      <div
        className={styles.background}
        aria-hidden="true"
        onClick={closeModal}
      />

      <section className={styles.box}>
        <h2 className={styles.header}>{t("sharePlan")}</h2>

        {isSubjectPlanLoading || isYearPlanLoading ? (
          <article className={styles.spinner__container}>
            <Spinner />
          </article>
        ) : isSubjectPlanError || isYearPlanError ? (
          <Error />
        ) : isInInviteView ? (
          <Invite
            goToOverview={() => setIsInIviteView(false)}
            planData={planType === "subject" ? subjectPlan : yearPlan}
            planType={planType}
            closeModal={closeModal}
          />
        ) : (
          <Overview
            goToInviteView={() => setIsInIviteView(true)}
            planData={planType === "subject" ? subjectPlan : yearPlan}
            closeModal={closeModal}
            planType={planType}
          />
        )}
      </section>
    </article>
  );
};

export default ShareModal;
