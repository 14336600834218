import { useQuery, useQueryClient } from "react-query";
import yearPlanAPI from "../../../api/yearPlanAPI";

export const useTakeYearPlanAccess = (token) => {
  const queryClient = useQueryClient();

  return useQuery(["access", token], () => yearPlanAPI.takePlanAccess(token), {
    enabled: !!token,
    onSuccess: () => queryClient.invalidateQueries("yearPlans"),
  });
};
