import { useEffect, useState } from "react";

export const useSyncFormDataWithExternalChanges = ({
  currentSubjectPlan,
  formMethods,
  fieldArrayMethods,
}) => {
  const [previouslyFetchedPlan, setPreviouslyFetchedPlan] = useState(null);
  const { setValue, getValues } = formMethods;
  const { append: appendActivity, remove: removeActivity } = fieldArrayMethods;

  useEffect(() => {
    if (!currentSubjectPlan) return;

    const arePlansEqual =
      JSON.stringify(currentSubjectPlan) ===
      JSON.stringify(previouslyFetchedPlan);

    if (arePlansEqual) return;

    // Update form with new data from server, but only for fields that have changed
    Object.keys(currentSubjectPlan)
      .filter(
        (item) =>
          !["access", "user", "updatedAt", "userId", "activities"].includes(
            item
          )
      )
      .forEach((key) => {
        if (previouslyFetchedPlan?.[key] !== currentSubjectPlan[key]) {
          setValue(key, currentSubjectPlan[key]);
        }
      });

    /// ACTIVITIES SYNC
    const activitiesInForm = getValues("activities");

    if (currentSubjectPlan.activities && previouslyFetchedPlan) {
      // Update existing activities with new data
      currentSubjectPlan.activities.forEach((activity, index) => {
        const previousActivity = previouslyFetchedPlan.activities[index];

        if (previousActivity) {
          Object.keys(activity).forEach((key) => {
            if (activity[key] !== previousActivity[key]) {
              setValue(`activities.${index}.${key}`, activity[key]);
            }
          });
        }
      });

      // Remove any surplus activities
      if (currentSubjectPlan.activities.length < activitiesInForm?.length) {
        activitiesInForm.forEach((_activity, index) => {
          if (!currentSubjectPlan.activities[index]) {
            removeActivity(index);
          }
        });
      }

      // Add any new activities
      currentSubjectPlan.activities.forEach((activity, index) => {
        if (!activitiesInForm[index]) {
          appendActivity(activity, { shouldFocus: false });
        }
      });
    }

    setPreviouslyFetchedPlan(currentSubjectPlan);
  }, [
    currentSubjectPlan,
    previouslyFetchedPlan,
    setValue,
    getValues,
    appendActivity,
    removeActivity,
  ]);
};
