import { useEffect, useCallback } from "react";
import { useQueryClient } from "react-query";
import debounce from "lodash.debounce";
import { useSubjectPlan } from "./useSubjectPlan";
import { usePatchSubjectPlan } from "./usePatchSubjectPlan";

export const useAutoSavePlan = ({ planId, formMethods, isSaving }) => {
  const queryClient = useQueryClient();
  const { data: currentSubjectPlan } = useSubjectPlan(planId);

  const {
    mutate: patchPlan,
    isLoading: isPatching,
    isError: isPatchingError,
  } = usePatchSubjectPlan();

  const watch = formMethods.watch();
  const { dirtyFields } = formMethods.formState;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((callback) => callback(), 1000),
    []
  );

  useEffect(() => {
    const shouldRunPatch =
      currentSubjectPlan &&
      !!Object.keys(dirtyFields).length &&
      !isSaving &&
      !isPatching;

    if (shouldRunPatch) {
      debounceCallback(() => patchUpdatedFields());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields, isSaving, isPatching, watch, currentSubjectPlan]);

  function patchUpdatedFields() {
    if (!dirtyFields) return;

    const filteredDirtyFields = removeFalsyValuesFromDirtyFields(dirtyFields);
    const fieldsToPatch = Object.keys(filteredDirtyFields).filter(
      (key) => !["access", "user", "updatedAt", "userId"].includes(key)
    );
    if (!Object.keys(fieldsToPatch).length) return;

    let post = {};

    fieldsToPatch.forEach((key) => {
      if (key === "activities" && hasDirtyActivities()) {
        const activitiesInForm = formMethods
          .getValues("activities")
          .map(updateActivityWithPosition);

        post["activities"] = activitiesInForm;
        return;
      }

      post[key] = formMethods.getValues(key);
    });

    function updateActivityWithPosition(activity, index) {
      const updatedActivity = {
        type: activity.type || "",
        resources: activity.resources || "",
        instructions: activity.instructions || "",
        homework: activity.homework || "",
        title: activity.title || "",
        position: index,
      };
      if (activity.id) {
        updatedActivity.id = activity.id;
      }
      return updatedActivity;
    }

    function hasDirtyActivities() {
      return (
        filteredDirtyFields.activities?.length &&
        filteredDirtyFields.activities.some(
          (activity) => !!Object.keys(activity).length
        )
      );
    }

    function isActivitiesChanged() {
      const activitiesInPost = post?.activities;
      const activitiesInCurrentPlan = currentSubjectPlan?.activities;

      if (activitiesInPost?.length !== activitiesInCurrentPlan?.length) {
        return true;
      }

      let foundChanges = false;

      activitiesInPost?.forEach((activity, index) => {
        Object.keys(activity).forEach((key) => {
          if (activity[key] !== activitiesInCurrentPlan[index][key]) {
            foundChanges = true;
          }
        });
      });

      return foundChanges;
    }

    if (!isActivitiesChanged()) {
      delete post.activities;
    }

    const shouldPatch = Object.keys(post).length > 0;

    if (!shouldPatch) return;

    patchPlan({ planId, data: post }, { onSuccess: updateFormWithReturnData });

    function updateFormWithReturnData(returnData) {
      formMethods.reset(returnData, { keepValues: true });

      // Update the id of the activities in the form if they're new
      if (returnData.activities) {
        const existingActivities = formMethods.getValues("activities");

        returnData.activities.forEach((activity, index) => {
          if (existingActivities[index] && !existingActivities[index].id) {
            formMethods.setValue(`activities.${index}.id`, activity.id);
          }
        });
      }

      queryClient.setQueryData(["plans", returnData.id], returnData);
    }
  }

  return {
    isPatching,
    isPatchingError,
  };
};

// Remove falsy values from dirtyFields object to avoid sending unnecessary values to the server
// This is necessary because the dirtyFields object sometimes contains all fields even if they're not dirty
function removeFalsyValuesFromDirtyFields(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    if (value === true) {
      acc[key] = value;
    } else if (Array.isArray(value)) {
      const filteredArray = value
        .map((item) => removeFalsyValuesFromDirtyFields(item))
        .map((item) => Object.assign({}, item));

      acc[key] = filteredArray;
    } else if (typeof value === "object" && value !== null) {
      acc[key] = removeFalsyValuesFromDirtyFields(value);
    }

    return acc;
  }, {});
}
