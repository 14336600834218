import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useTakeSubjectPlanAccess } from "../../features/subjectPlan/hooks/useTakeSubjectPlanAccess";

import Footer from "../_shared/Footer/Footer";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";

const SubjectPlanAccessTake = () => {
  const history = useHistory();
  const { token } = useParams();

  const { data: access, isLoading, isError } = useTakeSubjectPlanAccess(token);

  useEffect(() => {
    if (access) history.push(`/temaplan/visning/${access.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  return (
    <>
      <main>{isLoading ? <Spinner /> : isError ? <Error /> : null}</main>
      <Footer />
    </>
  );
};

export default SubjectPlanAccessTake;
