import { Trans } from "react-i18next";

const TranslatedText = ({ translationKey, values }) => (
  <Trans
    i18nKey={translationKey}
    values={values || {}}
    components={{
      // This stops linter complaining about empty <a> tag
      // eslint-disable-next-line
      a: <a />,
      b: <b />,
      ul: <ul />,
      ol: <ol />,
      li: <li />,
      span: <span />,
      div: <div />,
      em: <em />,
    }}
  />
);

export default TranslatedText;
