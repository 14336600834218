import axios from "axios";
import { API_BASE_URL } from "../constants";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      // network error
      return Promise.reject({
        response: { data: { message: "Error: Network Error" } },
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export const setBearer = (token) => {
  axios.defaults.headers.common = { Authorization: `bearer ${token}` };
};

export const setBaseUrl = (url) => {
  axios.defaults.baseURL = url;
};

export const url2data = async (url, params, method) => {
  let response;
  if (params) {
    if (method === "PUT") {
      response = await axios.put(url, params);
    } else if (method === "DELETE") {
      response = await axios.delete(url);
    } else {
      response = await axios.post(url, params);
    }
  } else {
    response = await duplicateGetStopper.get(url);
  }
  const { data } = response;
  return data;
};

export const fetchData = (url) =>
  axios.get(url).then((response) => response.data?.data);

export const patchData = (url, data) =>
  axios.patch(url, data).then((response) => response.data?.data);

export const deleteData = (url) => axios.delete(url);

export const updateData = (data, url) => {
  if (data?.id) {
    const { id, ...newData } = data;
    return url2data(`${url}/${id}`, newData, "PUT");
  }
  return url2data(url, data);
};

class DuplicateGetStopper {
  constructor() {
    this.cache = {};
  }

  get(url) {
    if (!url) return Promise.reject(new Error("Url not passed"));
    if (this.cache[url]) return this.cache[url];

    this.cache[url] = axios
      .get(url)
      .then((res) => {
        delete this.cache[url];
        return res;
      })
      .catch((err) => {
        delete this.cache[url];
        return err;
      });
    return this.cache[url];
  }
}

const duplicateGetStopper = new DuplicateGetStopper();

export default axios;
