import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Toolbox.module.css";

import Footer from "../_shared/Footer/Footer";

const Toolbox = () => {
  const { t } = useTranslation("toolbox");

  useEffect(() => {
    document.body.classList.add(styles.background);
    return () => {
      document.body.classList.remove(styles.background);
    };
  }, []);

  return (
    <>
      <main className={styles.main}>
        <h1>{t("header")}</h1>

        <article className={styles.tools__container}>
          {t("items", { returnObjects: true }).map((item, index) => (
            <section className={styles.tool} key={index}>
              <section className={styles.text}>
                {item.link ? (
                  <h2 className={styles.title}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {item.title}
                    </a>
                  </h2>
                ) : (
                  <h2 className={styles.title}>{item.title}</h2>
                )}
                <p>{item.description}</p>
                <p className={styles.source}>{item.source}</p>
              </section>

              <figure className={styles.image}>
                <a
                  href={item.imageLink ? item.imageLink : item.link}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={item.imageUrl} alt={item.title} />
                </a>
              </figure>
            </section>
          ))}
        </article>
      </main>
      <Footer />
    </>
  );
};

export default Toolbox;
