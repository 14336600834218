import { eachWeekOfInterval, endOfWeek } from "date-fns";
import styles from "./CalendarMonth.module.css";

import CalendarWeek from "./CalendarWeek";

const CalendarMonth = ({
  month,
  yearPlan,
  addSubjectPlan,
  editSubjectPlan,
}) => {
  const weeks = eachWeekOfInterval(month, { weekStartsOn: 1 });

  return (
    <section className={styles.container}>
      <p className={styles.year}>{month.start.getFullYear()}</p>
      <p className={styles.monthName}>
        {month.start.toLocaleString("no", { month: "long" })}
      </p>

      {weeks.map((week, index) => (
        <CalendarWeek
          key={`${week}-${index}`}
          week={{ start: week, end: endOfWeek(week, { weekStartsOn: 1 }) }}
          month={month}
          yearPlan={yearPlan}
          addSubjectPlan={addSubjectPlan}
          editSubjectPlan={editSubjectPlan}
        />
      ))}
    </section>
  );
};

export default CalendarMonth;
