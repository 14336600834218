import { useEffect, useMemo, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePDF } from "@react-pdf/renderer";
import styles from "./DisplayPlan.module.css";

import { WebSocketContext } from "../../features/socket/SocketContext";
import { useSubjectPlan } from "../../features/subjectPlan/hooks/useSubjectPlan";
import { useMyUser } from "../../features/user/hooks/useMyUser";
import RenderPDF from "./PDF/RenderPDF";

import { IconPdf, IconEdit } from "../../common/Icons/Icons";
import Footer from "../_shared/Footer/Footer";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";
import Activity from "./Activity";
import RichTextDisplay from "../_shared/RichTextDisplay/RichTextDisplay";
import labels from "../../common/labels.json";

const DisplayPlan = () => {
  const { t } = useTranslation("subjectPlan");
  const history = useHistory();
  const params = useParams();
  const id = Number(params.id);
  const { data: myUser } = useMyUser();

  const webSocket = useContext(WebSocketContext);
  const socket = webSocket.getSocket();

  const allExistingLabels = labels.map((group) => group.items).flat();

  useEffect(() => {
    webSocket.addSubjectPlanListener(id, false);
    return () => webSocket.removeSubjectPlanListener(id);
  }, [id, socket, webSocket]);

  const { data: plan, isLoading, isError } = useSubjectPlan(id);

  const splitSubject = plan?.subject.split(";");

  const [PDFInstance, updatePDFInstance] = usePDF({
    document: RenderPDF(plan),
  });

  // Add color to NavBar background
  useEffect(() => {
    document.body.classList.add(styles.nav__variable);
    return () => {
      document.body.classList.remove(styles.nav__variable);
    };
  }, []);

  // Update PDF document render when subject plan is ready
  useEffect(() => {
    if (plan) {
      updatePDFInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  function handleEditButtonClick() {
    history.push(`/temaplan/rediger/${plan.id}`);
    window.scrollTo({
      top: 0,
    });
  }

  function hasActivitiesWithContent(data) {
    if (
      data.length !== 1 ||
      data[0].type !== "" ||
      data[0].resources !== "" ||
      data[0].instructions !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  function downloadPDF() {
    if (!PDFInstance?.url) return;

    let link = document.createElement("a");
    link.href = PDFInstance?.url;
    link.download = `${plan.title?.slice(0, 100) || "temaplan"}.pdf`;
    link.target = "_blank";
    link.rel = "norefferer noopener";

    link.dispatchEvent(new MouseEvent("click"));
  }

  const canEditPlan = useMemo(() => {
    if (!myUser || !plan) return false;

    if (myUser.id === plan.userId) {
      return true;
    }
    // Return true if the user has write ("W") access to the plan
    const myAccess = plan.access?.find((access) => access.userId === myUser.id);
    return myAccess?.access === "W" ? true : false;
  }, [myUser, plan]);

  return (
    <>
      <main>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Error />
        ) : plan ? (
          <section className={styles.container}>
            <article className={styles.container__summary}>
              <section className={styles.text}>
                <h1 className={styles.title}>{plan.title}</h1>

                {plan.grade ? (
                  <p className={styles.grade}>
                    {plan.grade.split(";").join(". trinn, ")}. trinn
                  </p>
                ) : null}

                {plan.subject
                  ? splitSubject.map((subject, key) => {
                      return (
                        <h2 key={key} className={styles.subject}>
                          {subject}
                        </h2>
                      );
                    })
                  : null}

                {!!plan.time && (
                  <p className={styles.time}>
                    {t("labels.time")}: {plan.time} {t("general.sessions")}
                  </p>
                )}

                <p className={styles.createdBy}>
                  {t("general.createdBy")}: {plan.user?.fullName || "–"}
                </p>
              </section>

              <section className={styles.buttons}>
                <button
                  className={styles.button}
                  onClick={downloadPDF}
                  disabled={!PDFInstance?.url}
                >
                  <IconPdf />
                  <span>{t("general.saveAsPdf")}</span>
                </button>

                {canEditPlan && (
                  <button
                    className={styles.button}
                    onClick={handleEditButtonClick}
                  >
                    <IconEdit />
                    <span>{t("general.edit")}</span>
                  </button>
                )}
              </section>
            </article>

            {/*  */}

            {plan.concepts ||
            plan.challenges ||
            plan.goals ||
            plan.curriculumBase ||
            plan.reason ? (
              <article className={styles.mainText__container}>
                <section className={styles.mainText__body}>
                  {!!plan.concepts && (
                    <>
                      <h3 className={styles.subHeader}>
                        {t("labels.concepts-short")}
                      </h3>
                      <p>{plan.concepts}</p>
                    </>
                  )}

                  {!!plan.challenges && (
                    <>
                      <h3 className={styles.subHeader}>
                        {t("labels.challenges-short")}
                      </h3>
                      <p>{plan.challenges}</p>
                    </>
                  )}

                  {!!plan.goals && (
                    <>
                      <h3 className={styles.subHeader}>{t("labels.goals")}</h3>
                      <p>{plan.goals}</p>
                    </>
                  )}

                  {!!plan.curriculumBase && (
                    <>
                      <h3 className={styles.subHeader}>
                        {t("labels.curriculumBase")}
                      </h3>
                      <RichTextDisplay value={plan.curriculumBase} />
                    </>
                  )}

                  {!!plan.reason && (
                    <>
                      <h3 className={styles.subHeader}>{t("labels.reason")}</h3>
                      <p>{plan.reason}</p>
                    </>
                  )}
                </section>
              </article>
            ) : null}

            {/* START PHASE */}
            {plan.type || plan.resources || plan.instructions ? (
              <article className={styles.activities}>
                <div className={styles.sideLine} />
                <h2 className={styles.sectionHeader}>
                  {t("labels.startPhase-header")}
                </h2>

                <Activity
                  activity={{
                    type: plan.type,
                    resources: plan.resources,
                    instructions: plan.instructions,
                  }}
                  index={0}
                />
              </article>
            ) : null}
            {/*  */}

            {/* ACTIVITIES */}
            {plan.activities?.length > 0 &&
            hasActivitiesWithContent(plan.activities) ? (
              <article
                className={`${styles.activities} ${styles.researchActivities}`}
              >
                <div
                  className={`${styles.sideLine} ${styles.researchPhaseSideLine}`}
                />
                <h2 className={styles.sectionHeader}>
                  {t("general.researchPhase")}
                </h2>

                {plan.activities
                  ?.sort((a, b) => a.position - b.position)
                  ?.map((activity, index) => (
                    <Activity
                      key={`researchActivity-${index}`}
                      activity={activity}
                      index={index}
                      isResearchPhase
                    />
                  ))}
              </article>
            ) : null}
            {/*  */}

            <article className={styles.container__assessment}>
              {plan.labels ||
              plan.assessment ||
              plan.involvement ||
              plan.qualityMarkers ||
              plan.feedback ? (
                <h2 className={styles.sectionHeader}>
                  {t("general.assessment")}
                </h2>
              ) : null}

              {!!plan.assessment && (
                <>
                  <h3 className={styles.header}>
                    {t("labels.assessment-sub-short")}
                  </h3>
                  <p>{plan.assessment}</p>
                </>
              )}

              {!!plan.involvement && (
                <>
                  <h3 className={styles.header}>{t("labels.involvement")}</h3>
                  <p>{plan.involvement}</p>
                </>
              )}

              {!!plan.qualityMarkers && (
                <>
                  <h3 className={styles.header}>
                    {t("labels.qualityMarkers")}
                  </h3>
                  <p>{plan.qualityMarkers}</p>
                </>
              )}

              {!!plan.feedback && (
                <>
                  <h3 className={styles.header}>{t("labels.feedback")}</h3>
                  <p>{plan.feedback}</p>
                </>
              )}

              {!!plan.labels && (
                <>
                  <h3 className={styles.header}>{t("labels.tags-short")}</h3>
                  <section className={styles.container__labels}>
                    {plan.labels
                      .split(",")
                      .filter((label) => allExistingLabels.includes(label))
                      ?.map((label, index) => (
                        <span className={styles.label} key={index}>
                          {t(`labelTags.tags.${label}`)}
                        </span>
                      ))}
                  </section>
                </>
              )}
            </article>
          </section>
        ) : (
          <p>{t("noPlansToShow")}</p>
        )}
      </main>

      <Footer />
    </>
  );
};

export default DisplayPlan;
