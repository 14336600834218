import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Home.module.css";

import { useYearPlans } from "../../features/yearPlan/hooks/useYearPlans";
import { useSubjectPlans } from "../../features/subjectPlan/hooks/useSubjectPlans";

import { ArrowRight, ChevronDown } from "../../common/Icons/Icons";
import Footer from "../_shared/Footer/Footer";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";
import EditYearPlanModal from "../_shared/Modals/EditYearPlanModal";

const Home = () => {
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(getCurrentSchoolYear());
  const [isNewPlanModalOpened, setIsNewPlanModalOpened] = useState(false);

  const { t } = useTranslation("common");
  const history = useHistory();
  const { data: plans, isLoading, isError } = useSubjectPlans();
  const {
    data: yearPlans,
    isLoading: isYearPlansLoading,
    isError: isYearPlansError,
  } = useYearPlans();

  // Change background color variables
  useEffect(() => {
    document.body.classList.add(styles.background);
    return () => document.body.classList.remove(styles.background);
  }, []);

  // Generate an array with years from 2020 to the current year + 2
  useEffect(() => {
    const schoolYearNow = getCurrentSchoolYear();
    const yearOptions = [];

    for (let i = 2020; i <= schoolYearNow + 2; i++) {
      yearOptions.push(i);
    }

    setYearOptions(yearOptions);
  }, []);

  // When a new year is selected, update the selected year
  function handleYearChange(event) {
    setSelectedYear(event.target.value);
  }

  function handleMakeNewSubjectPlan() {
    history.push("/temaplan/ny");
    window.scrollTo({
      top: 0,
    });
  }

  function handleMakeNewYearPlan() {
    setIsNewPlanModalOpened(true);
  }

  function getCurrentSchoolYear() {
    const monthNow = new Date().getMonth();
    const yearNow = new Date().getFullYear();
    const schoolYearNow = monthNow < 8 ? yearNow - 1 : yearNow;
    return schoolYearNow;
  }

  const sortByDate = (a, b) =>
    a?.updatedAt < b?.updatedAt ? 1 : a?.updatedAt > b?.updatedAt ? -1 : 0;

  return (
    <>
      <main className={styles.container}>
        <article className={styles.plans}>
          <h2 className={styles.plans__header}>{t("currentSubjectPlans")}</h2>

          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <Error />
          ) : plans?.length > 0 ? (
            <article className={styles.cards__container}>
              {[...plans]
                .sort(sortByDate)
                .slice(0, 7)
                .map((plan, index) => (
                  <NavLink
                    to={`/temaplan/visning/${plan.id}`}
                    className={styles.card}
                    key={index}
                  >
                    <h3 className={styles.title}>
                      {plan.title}
                      <span
                        role="img"
                        className={styles.arrow}
                        aria-hidden="true"
                      >
                        <ArrowRight />
                      </span>
                    </h3>

                    {plan.subject?.split(";")?.map(
                      (subject, index) =>
                        !!subject && (
                          <span
                            key={`${subject}-${index}`}
                            className={styles.subject}
                          >
                            {subject}
                          </span>
                        )
                    )}
                  </NavLink>
                ))}
            </article>
          ) : (
            <article className={styles.nothingToShow__container}>
              <h3 className={styles.nothingToShow__text}>
                {t("noPlansToShow")}
              </h3>
            </article>
          )}

          <button
            className={styles.newPlan__button}
            onClick={handleMakeNewSubjectPlan}
          >
            {t("makeNewSubjectPlan")}
          </button>
        </article>

        <article className={styles.plans}>
          <h2 className={styles.plans__header}>
            {t("yearPlansForSchoolyear")}

            <section className={styles.yearSelectorContainer}>
              <select
                className={styles.yearSelector}
                value={selectedYear}
                onChange={handleYearChange}
                aria-label="Velg år"
              >
                {yearOptions.map((year, index) => (
                  <option key={`${year}-${index}`} value={year}>
                    {year} - {year + 1}
                  </option>
                ))}
              </select>
              <ChevronDown className={styles.dropdownArrow} />
            </section>
          </h2>

          {isYearPlansLoading ? (
            <Spinner />
          ) : isYearPlansError ? (
            <Error />
          ) : yearPlans?.filter((item) => item.year === +selectedYear)?.length >
            0 ? (
            <article className={styles.cards__container}>
              {yearPlans
                ?.filter((item) => item.year === +selectedYear)
                ?.map((yearPlan, index) => (
                  <NavLink
                    to={`/arsplan/${yearPlan.id}`}
                    className={styles.yearPlanCard}
                    key={`${yearPlan.id}-${index}`}
                  >
                    <h3 className={styles.title}>
                      {yearPlan.title || "-"}
                      <span
                        role="img"
                        className={styles.arrow}
                        aria-hidden="true"
                      >
                        <ArrowRight />
                      </span>
                    </h3>
                  </NavLink>
                ))}
            </article>
          ) : (
            <article className={styles.nothingToShow__container}>
              <h3 className={styles.nothingToShow__text}>
                {t("noYearPlansToShow")}
              </h3>
            </article>
          )}

          <button
            className={styles.newPlan__button}
            onClick={handleMakeNewYearPlan}
          >
            {t("makeNewYearPlan")}
          </button>
        </article>
      </main>
      <Footer />

      {isNewPlanModalOpened && (
        <EditYearPlanModal closeModal={() => setIsNewPlanModalOpened(false)} />
      )}
    </>
  );
};

export default Home;
