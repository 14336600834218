import { useState } from "react";
import styles from "./CollapseInput.module.css";

import ExpandingTextArea from "../../_shared/Inputs/ExpandingTextArea";
import { ChevronDown } from "../../../common/Icons/Icons";

const CollapseInput = ({ label, defaultInputValue, id, description }) => {
  const [isOpen, setIsOpen] = useState(defaultInputValue ? true : false);

  return (
    <section className={styles.container}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={styles.button}
        type="button"
        aria-expanded={isOpen}
        aria-controls={id}
      >
        {label}
        <ChevronDown
          className={`${styles.dropdownArrow} ${isOpen ? styles.open : ""}`}
        />
      </button>

      {isOpen && (
        <>
          <ExpandingTextArea
            name={id}
            id={id}
            defaultValue={defaultInputValue}
            minHeight={7}
            aria-label={id}
          />

          {!!description && (
            <span className={styles.description}>{description}</span>
          )}
        </>
      )}
    </section>
  );
};

export default CollapseInput;
