import { useMutation, useQueryClient } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useUpdateSubjectPlanAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(subjectPlanAPI.updatePlanAccess, {
    onSuccess: () => queryClient.invalidateQueries("plans"),
    onError: (error) => console.log("share access update error: ", error),
  });
};
