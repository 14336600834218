import { useTranslation } from "react-i18next";
import styles from "./PrivacyBox.module.css";

import { useBlockBodyScrollOnMount } from "../../../common/hooks/useBlockBodyScrollOnMount";
import TranslatedText from "../Translation/TranslatedText";

const PrivacyBox = ({ onYes, onNo }) => {
  useBlockBodyScrollOnMount();

  const { t } = useTranslation("common");

  return (
    <article>
      <div className={styles.background} aria-hidden="true" />

      <section className={styles.box}>
        <section className={`${styles.subSection} ${styles.cookies}`}>
          <h1>{t("privacy.mainHeader")}</h1>

          <h3 className={styles.subHeader}>{t("privacy.cookies.header")}</h3>
          <p className={styles.description}>
            <TranslatedText translationKey="common:privacy.cookies.description" />
          </p>
        </section>

        <section className={`${styles.subSection} ${styles.hotjar}`}>
          <h3 className={styles.subHeader}>{t("privacy.hotjar.header")}</h3>
          <p className={styles.description}>
            <TranslatedText translationKey="common:privacy.hotjar.description" />
          </p>

          <section className={styles.buttons__container}>
            <button
              className={`${styles.button} ${styles.yesButton}`}
              onClick={onYes}
            >
              {t("privacy.buttons.yes")}
            </button>
            <button
              className={`${styles.button} ${styles.noButton}`}
              onClick={onNo}
            >
              {t("privacy.buttons.no")}
            </button>
          </section>
        </section>
      </section>
    </article>
  );
};

export default PrivacyBox;
