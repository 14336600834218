import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./Invite.module.css";

import { ChevronDown } from "../../../common/Icons/Icons";
import { useSaveSubjectPlanAccess } from "../../../features/subjectPlan/hooks/useSaveSubjectPlanAccess";
import { useSaveYearPlanAccess } from "../../../features/yearPlan/hooks/useSaveYearPlanAccess";

const Invite = ({ planData, goToOverview, closeModal, planType }) => {
  const { t } = useTranslation("common");
  const methods = useForm();

  const { mutate: saveSubjectPlanAccess } = useSaveSubjectPlanAccess();
  const { mutate: saveYearPlanAccess } = useSaveYearPlanAccess();

  useEffect(() => {
    methods.reset({ email: "", access: "R", userId: null });
  }, [methods, planData.id]);

  function handleCancelClick() {
    goToOverview();
  }

  function onSubmit(data) {
    const post = { email: data.email, access: data.access };

    if (planType === "subject") {
      saveSubjectPlanAccess(
        { data: post, id: planData.id },
        { onSuccess: closeModal }
      );
    } else if (planType === "year") {
      saveYearPlanAccess(
        { data: post, id: planData.id },
        { onSuccess: closeModal }
      );
    }
  }

  return (
    <section className={styles.container}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <section className={styles.inputs}>
          <div>
            <label htmlFor="email">{t("email")}</label>
            <input
              type="email"
              id="email"
              {...methods.register("email")}
            ></input>
          </div>

          <div>
            <select className={styles.select} {...methods.register("access")}>
              <option value="W">{t("toEdit")}</option>
              <option value="R">{t("toRead")}</option>
            </select>
            <ChevronDown className={styles.dropdownArrow} />
          </div>
        </section>

        <button className={`${styles.button} ${styles.send}`} type="submit">
          {t("send")}
        </button>

        <button
          className={`${styles.button} ${styles.cancel}`}
          type="button"
          onClick={handleCancelClick}
        >
          {t("cancel")}
        </button>
      </form>
    </section>
  );
};

export default Invite;
