import { useEffect } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useHistory, Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Editor.module.css";

import { useSubjectPlan } from "../../../features/subjectPlan/hooks/useSubjectPlan";
import { useAutoSavePlan } from "../../../features/subjectPlan/hooks/useAutoSavePlan.js";
import { useSaveSubjectPlan } from "../../../features/subjectPlan/hooks/useSaveSubjectPlan";
import { useSyncFormDataWithExternalChanges } from "../../../features/subjectPlan/hooks/useSyncFormDataWithExternalChanges.js";
import { formatPlanForDatabase } from "../../../features/subjectPlan/helpers.js";
import labels from "../../../common/labels.json";

import Label from "./Label";
import {
  IconEye,
  IconCheckMark,
  IconDocument,
} from "../../../common/Icons/Icons";
import ExpandingTextArea from "../../_shared/Inputs/ExpandingTextArea";
import TranslatedText from "../../_shared/Translation/TranslatedText";
import Activities from "./Activities";

const emptyActivity = {
  title: "",
  type: "",
  resources: "",
  instructions: "",
  homework: "",
};

const defaultFormValues = {
  labels: "",
  assessment: "",
  involvement: "",
  qualityMarkers: "",
  feedback: "",
  type: "",
  resources: "",
  instructions: "",
  summary: "",
  activities: [emptyActivity],
};

const Editor = ({ planId }) => {
  const history = useHistory();
  const { t } = useTranslation("subjectPlan");

  const { data: currentSubjectPlan } = useSubjectPlan(planId);

  const formMethods = useForm({
    defaultValues: currentSubjectPlan || defaultFormValues,
  });

  const fieldArrayMethods = useFieldArray({
    name: "activities",
    keyName: "fieldId",
    control: formMethods.control,
  });

  const { isDirty, isSubmitting } = formMethods.formState;

  const {
    mutate: savePlan,
    isLoading: isSaving,
    isError: isSavingError,
  } = useSaveSubjectPlan();

  useSyncFormDataWithExternalChanges({
    currentSubjectPlan,
    fieldArrayMethods,
    formMethods,
  });

  const { isPatching, isPatchingError } = useAutoSavePlan({
    planId,
    formMethods,
    isSaving,
  });

  // Add a warning if user tries to close the page without saving
  useEffect(() => {
    if (isDirty) {
      window.removeEventListener("beforeunload", warnUser);
    } else {
      window.addEventListener("beforeunload", warnUser);
    }

    function warnUser(e) {
      e.preventDefault();
      e.returnValue = "";
    }

    return () => window.removeEventListener("beforeunload", warnUser);
  }, [isDirty]);

  function onSubmit(data) {
    if (isSubmitting) return;

    const formattedData = formatPlanForDatabase({
      ...currentSubjectPlan,
      ...data,
    });
    const post = { ...formattedData, id: +planId };

    savePlan(post);
  }

  function handleDisplayPlanButtonClick() {
    if (!isDirty) {
      goToPlanDisplay();
    } else {
      if (wantsToLeaveWithoutSaving()) goToPlanDisplay();
    }
  }

  function handleToSubjectPlansButtonClick() {
    if (!isDirty) {
      goToSubjectPlans();
    } else {
      if (wantsToLeaveWithoutSaving()) goToSubjectPlans();
    }
  }

  function wantsToLeaveWithoutSaving() {
    return window.confirm(t("common:confirmations.leaveWithoutSaving"));
  }

  function goToPlanDisplay() {
    history.push(`/temaplan/visning/${planId}`);
    window.scrollTo({
      top: 0,
    });
  }

  function goToSubjectPlans() {
    history.push(`/temaplanar`);
    window.scrollTo({
      top: 0,
    });
  }

  return (
    <FormProvider {...formMethods}>
      {currentSubjectPlan ? (
        <form
          className={styles.container}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <h2>{t("general.assessment")}</h2>
          <p>
            <TranslatedText
              translationKey={"subjectPlan:descriptions.assessment-general"}
            />
          </p>

          <article className={styles.inputs}>
            <label htmlFor="assessment">
              {t("labels.assessment")}
              <span className={styles.subLabel}>
                {t("labels.assessment-sub")}
              </span>
            </label>
            <ExpandingTextArea
              name="assessment"
              id="assessment"
              minHeight={13}
            />

            <span className={styles.description}>
              <TranslatedText translationKey="subjectPlan:descriptions.assessment" />
            </span>

            <label htmlFor="involvement">{t("labels.involvement")}</label>
            <ExpandingTextArea
              name="involvement"
              id="involvement"
              minHeight={13}
            />

            <span className={styles.description}>
              <TranslatedText translationKey="subjectPlan:descriptions.involvement" />
            </span>

            <label htmlFor="qualityMarkers">{t("labels.qualityMarkers")}</label>
            <ExpandingTextArea
              name="qualityMarkers"
              id="qualityMarkers"
              minHeight={7}
            />

            <span className={styles.description}>
              <TranslatedText translationKey="subjectPlan:descriptions.qualityMarkers" />
            </span>

            <label htmlFor="feedback">{t("labels.feedback")}</label>
            <ExpandingTextArea name="feedback" id="feedback" minHeight={7} />

            <span className={styles.description}>
              <TranslatedText translationKey="subjectPlan:descriptions.feedback" />
            </span>
          </article>

          {/* LABELS */}
          <article className={styles.container__labels}>
            <h3>{t("labels.tags")}</h3>
            <p>{t("descriptions.tags")}</p>

            <section className={styles.labels__grid}>
              {labels.map((section, index) => (
                <section key={index} className={styles.labels__column}>
                  <h3 className={styles.labels__header}>
                    {section.id}. {t(`labelTags.sectionTitles.${section.name}`)}
                  </h3>

                  <section className={styles.labels__list}>
                    {section.items.map((item) => (
                      <Label label={item} key={item} />
                    ))}
                  </section>
                </section>
              ))}
            </section>
          </article>

          <article className={styles.inputs}>
            <section className={styles.activities__container}>
              <h2 className={styles.header}>{t("labels.startPhase-header")}</h2>

              <span
                className={`${styles.description} ${styles.activities__mainDescription}`}
              >
                <TranslatedText translationKey="subjectPlan:descriptions.startPhase" />
              </span>

              <section className={styles.activity}>
                <label htmlFor={`instructions`}>
                  {t("labels.startPhase-knowledge")}
                </label>
                <ExpandingTextArea
                  name={`instructions`}
                  id={`instructions`}
                  minHeight={7}
                />

                <label htmlFor={`type`}>
                  {t("labels.startPhase-activity")}
                </label>
                <ExpandingTextArea name={`type`} id={`type`} minHeight={7} />

                <label htmlFor={`resources`}>
                  {t("labels.activity-resources-short")}
                </label>
                <ExpandingTextArea
                  name={`resources`}
                  id={`resources`}
                  minHeight={7}
                />
              </section>
            </section>

            <Activities methods={fieldArrayMethods} />

            <label htmlFor="summary">{t("labels.summary")}</label>
            <ExpandingTextArea name="summary" id="summary" minHeight={7} />

            <span className={styles.description}>
              <TranslatedText translationKey="subjectPlan:descriptions.summary" />
            </span>
          </article>

          {/* BUTTONS Area */}
          <section className={styles.finalButtons__container}>
            {/* SAVE ERROR MESSAGE */}
            {isSavingError || isPatchingError ? (
              <span className={styles.saveError}>
                {t("common:savingError")}
              </span>
            ) : null}

            <section className={styles.saveButton__container}>
              <button
                type="submit"
                className={`${styles.save} ${styles.button}`}
                disabled={!isDirty}
              >
                {isSaving || isPatching
                  ? t("general.loading")
                  : !isDirty
                  ? t("general.saved")
                  : t("general.savePlan")}
              </button>

              {!isDirty && !isSaving && !isPatching ? (
                <span className={styles.saved}>
                  <IconCheckMark />
                  {t("general.saved")}!
                </span>
              ) : null}
            </section>

            <button
              type="button"
              className={styles.button}
              onClick={handleDisplayPlanButtonClick}
              id="goto-display-button"
            >
              <IconEye />
              <span>{t("general.showPlan")}</span>
            </button>

            <button
              type="button"
              className={styles.button}
              onClick={handleToSubjectPlansButtonClick}
            >
              <IconDocument />
              <span>{t("general.toSubjectPlans")}</span>
            </button>
          </section>
        </form>
      ) : null}
      <Prompt
        when={!!isDirty}
        message={t("common:confirmations.leaveWithoutSaving")}
      />
    </FormProvider>
  );
};

export default Editor;
