import { useContext, useEffect } from "react";
import { WebSocketContext } from "../../socket/SocketContext";

export const useSubjectPlanListener = (planId) => {
  const webSocket = useContext(WebSocketContext);
  const socket = webSocket.getSocket();

  useEffect(() => {
    if (!socket || !planId) return;

    webSocket.addSubjectPlanListener(planId, true);

    return () => webSocket.removeSubjectPlanListener(planId);
  }, [socket, planId, webSocket]);
};
