import { useMemo } from "react";
import {
  eachWeekOfInterval,
  setWeek,
  startOfWeek,
  endOfWeek,
  getWeek,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { useAllYearPlans } from "../../features/yearPlan/hooks/useAllYearPlans";
import CalendarPlan from "./CalendarPlan";
import styles from "./CompareCalendar.module.css";

const CompareCalendar = ({
  selectedPlanIds,
  addSubjectPlan,
  editSubjectPlan,
}) => {
  const { data: yearPlans } = useAllYearPlans();

  const { t } = useTranslation("common");

  const weekOptions = { weekStartsOn: 1, firstWeekContainsDate: 4 };
  const startWeekNumber = 30;
  const endWeekNumber = 29;

  const intervalStartDate = startOfWeek(
    setWeek(new Date(2000, 1, 1), startWeekNumber, weekOptions),
    weekOptions
  );

  const intervalEndDate = endOfWeek(
    setWeek(new Date(2001, 1, 1), endWeekNumber, weekOptions),
    weekOptions
  );

  const timeInterval = { start: intervalStartDate, end: intervalEndDate };
  const firstDaysOfWeeks = eachWeekOfInterval(timeInterval, weekOptions);

  const selectedPlans = useMemo(
    () =>
      yearPlans?.length
        ? selectedPlanIds
            .filter(Boolean)
            ?.map((planId) => yearPlans?.find((plan) => plan.id === planId)) ||
          []
        : [],
    [yearPlans, selectedPlanIds]
  );

  return selectedPlanIds.filter(Boolean)?.length > 0 ? (
    <article className={styles.container}>
      <section className={`${styles.headerRow} ${styles.row1}`}>
        <div className={styles.weekHeader}></div>

        {selectedPlans.map((plan, index) => (
          <div className={styles.planHeader} key={`planHeader-title-${index}`}>
            <section className={styles.title}>{plan.title || "–"}</section>
          </div>
        ))}
      </section>

      <section className={`${styles.headerRow} ${styles.row2}`}>
        <div className={styles.weekHeader}>
          <span>{t("week")}</span>
        </div>

        {selectedPlans.map((plan, index) => (
          <div className={styles.planHeader} key={`planHeader-sub-${index}`}>
            <section className={styles.subtitle}>
              <span className={styles.line}>
                {plan.year ? `${plan.year}-${plan.year + 1}` : "–"}
              </span>
              <span className={styles.line}>{plan.subject || "–"}</span>
              <span className={styles.line}>{plan.grade || "–"}. trinn</span>
            </section>
          </div>
        ))}
      </section>

      <article className={styles.calendar}>
        <section className={styles.weekNumbersColumn}>
          {firstDaysOfWeeks?.map((day, index) => (
            <div
              key={`week-${index}`}
              className={`${styles.weekNumberCell} ${
                getWeek(day, weekOptions) === 52 ? styles.isLastWeekOfYear : ""
              }`}
            >
              <span>{getWeek(day, weekOptions)}</span>
            </div>
          ))}
        </section>

        {selectedPlans?.map((plan, index) => (
          <CalendarPlan
            key={`plan-${index}`}
            yearPlan={plan}
            timeInterval={timeInterval}
            startWeekNumber={startWeekNumber}
            endWeekNumber={endWeekNumber}
            addSubjectPlan={addSubjectPlan}
            editSubjectPlan={editSubjectPlan}
          />
        ))}
      </article>
    </article>
  ) : null;
};

export default CompareCalendar;
