import React from "react";
import styles from "./Footer.module.css";
import {
  LogoForsknigsradet,
  LogoJaerskulen,
  LogoLesesenteret,
} from "../../../common/Logos/Logos";

const Footer = () => {
  return (
    <footer className={styles.container}>
      <section className={styles.line1}>
        <LogoJaerskulen />
        <LogoLesesenteret />
      </section>
      <section className={styles.line2}>
        <LogoForsknigsradet />
        <a
          href="https://www.uis.no/nb/personvernerklaering-digup"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.link}
        >
          Informasjon om personvern
        </a>
      </section>
    </footer>
  );
};

export default Footer;
