export function formatPlanForDatabase(data) {
  const newData = {
    title: data.title?.trim() || "",
    grade:
      typeof data.grade === "string"
        ? data.grade
            .split(";")
            .map((item) => item.trim())
            .filter(Boolean)
            .join(";")
        : "",
    subject:
      typeof data.subject === "string"
        ? data.subject
            .split(";")
            .map((item) => item.trim())
            .filter(Boolean)
            .join(";")
        : "",
    curriculumBase: data.curriculumBase || "",
    goals: data.goals || "",
    reason: data.reason || "",
    challenges: data.challenges || "",
    concepts: data.concepts || "",
    time: +data.time || 0,
    qualityMarkers: data.qualityMarkers || "",
    labels: data.labels
      ? Array.isArray(data.labels) && data.labels?.length > 0
        ? data.labels?.join(",")
        : !Array.isArray(data.labels)
        ? data.labels
        : ""
      : "",
    involvement: data.involvement || "",
    feedback: data.feedback || "",
    assessment: data.assessment || "",
    summary: data.summary || "",
    type: data.type || "",
    resources: data.resources || "",
    instructions: data.instructions || "",
    activities:
      data.activities?.map((activity, index) => ({
        ...activity,
        position: index,
      })) || [],
  };

  if (data.id) newData.id = data.id;

  return newData;
}
