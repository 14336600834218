import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useTakeYearPlanAccess } from "../../features/yearPlan/hooks/useTakeYearPlanAcess";

import Footer from "../_shared/Footer/Footer";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";

const YearPlanAccessTake = () => {
  const history = useHistory();
  const { token } = useParams();

  const { data: access, isLoading, isError } = useTakeYearPlanAccess(token);

  useEffect(() => {
    if (access) history.push(`/arsplan/${access.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  return (
    <>
      <main>{isLoading ? <Spinner /> : isError ? <Error /> : null}</main>
      <Footer />
    </>
  );
};

export default YearPlanAccessTake;
