import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Summary.module.css";

import { useSubjectPlan } from "../../../features/subjectPlan/hooks/useSubjectPlan";
import { selectAuthUserId } from "../../../features/auth/AuthSlice";

import {
  ChevronDown,
  IconEdit,
  IconEye,
  IconShare,
} from "../../../common/Icons/Icons";

const Summary = ({ planId, sharePlan }) => {
  const { t } = useTranslation("subjectPlan");
  const history = useHistory();

  const [isGoalsOpen, setIsGoalsOpen] = useState(false);
  const [isConceptsOpen, setIsConceptsOpen] = useState(false);

  const { data: plan } = useSubjectPlan(planId);
  const userId = useSelector(selectAuthUserId);

  const splitSubject = plan.subject.split(";");

  function goToPlanDisplay() {
    history.push(`/temaplan/visning/${planId}`);
    window.scrollTo({
      top: 0,
    });
  }

  function goToEditPlan() {
    history.push(`/temaplan/ny/${planId}`);
    window.scrollTo({
      top: 0,
    });
  }

  function handleSharePlanClick() {
    sharePlan();
  }

  return (
    <section className={styles.container}>
      {plan ? (
        <>
          <h1 className={styles.title}>{plan.title}</h1>

          {plan.grade ? (
            <h2 className={styles.grade}>
              {plan.grade.split(";").join(". trinn, ")}. trinn
            </h2>
          ) : null}

          {plan.subject
            ? splitSubject.map((subject, key) => (
                <h2 key={key} className={styles.subject}>
                  {subject}
                </h2>
              ))
            : null}

          <h3 className={styles.time}>
            {t("labels.time")}:{" "}
            {plan.time ? `${plan.time} ${t("general.sessions")}` : `-`}
          </h3>

          <h2>
            <button
              className={styles.button__showMore}
              onClick={() => setIsGoalsOpen((prev) => !prev)}
              aria-expanded={isGoalsOpen}
              aria-controls="goals-dropdown"
            >
              {t("labels.goals")}
              <ChevronDown
                className={`${styles.dropdownArrow} ${
                  isGoalsOpen ? styles.open : ""
                }`}
              />
            </button>
          </h2>

          {isGoalsOpen && (
            <p className={styles.animateOpen} id="goals-dropdown">
              {plan.goals || t("common:notFilledOut")}
            </p>
          )}

          <h2>
            <button
              className={styles.button__showMore}
              onClick={() => setIsConceptsOpen((prev) => !prev)}
              aria-expanded={isConceptsOpen}
              aria-controls="concepts-dropdown"
            >
              {t("labels.concepts")}
              <ChevronDown
                className={`${styles.dropdownArrow} ${
                  isConceptsOpen ? styles.open : ""
                }`}
              />
            </button>
          </h2>

          {isConceptsOpen && (
            <p className={styles.animateOpen} id="concepts-dropdown">
              {plan.concepts || t("common:notFilledOut")}
            </p>
          )}

          <h2 className={styles.challenges__heading}>
            {t("labels.challenges-short")}
          </h2>
          <p>{plan.challenges || t("common:notFilledOut")}</p>

          <section className={styles.options__container}>
            {userId === plan.userId && (
              <button onClick={handleSharePlanClick}>
                <IconShare />
                <span>{t("general.sharePlan")}</span>
              </button>
            )}

            <button onClick={goToPlanDisplay}>
              <IconEye />
              <span>{t("general.showPlan")}</span>
            </button>

            <button onClick={goToEditPlan}>
              <IconEdit />
              <span>{t("general.edit")}</span>
            </button>
          </section>
        </>
      ) : null}
    </section>
  );
};

export default Summary;
