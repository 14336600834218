import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styles from "./YearPlanTableItem.module.css";

import { useSaveYearPlan } from "../../features/yearPlan/hooks/useSaveYearPlan";
import { useSaveSubjectPlanInYearPlan } from "../../features/yearPlan/hooks/useSaveSubjectPlanInYearPlan";
import { useDeleteYearPlan } from "../../features/yearPlan/hooks/useDeleteYearPlan";

import {
  IconDuplicate,
  IconEye,
  IconShare,
  IconTrash,
} from "../../common/Icons/Icons.jsx";

const YearPlanTableItem = ({
  yearPlan,
  showShareModal,
  freezeClassName,
  displaySharedOnly,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { t } = useTranslation("subjectPlan");

  const { mutate: deleteYearPlan } = useDeleteYearPlan();
  const { mutate: saveYearPlan } = useSaveYearPlan();
  const { mutate: saveSubjectPlanInYearPlan } = useSaveSubjectPlanInYearPlan();

  function handleDeleteItem() {
    const decision = window.confirm(
      t("common:confirmations.removeSubjectPlan")
    );

    if (decision) deleteYearPlan(yearPlan.id);
  }

  function handleDisplayItem() {
    history.push(`/arsplan/${yearPlan.id}`);
    window.scrollTo({
      top: 0,
    });
  }

  function handleShareButtonClick() {
    showShareModal(yearPlan.id);
  }

  function handleDuplicatePlan() {
    const newYearPlan = {
      year: yearPlan.year || null,
      subject: yearPlan.subject || "",
      grade: yearPlan.grade || null,
      title: `${yearPlan.title || t("common:unknown")} - kopi`,
    };

    saveYearPlan(newYearPlan, {
      onSuccess: (response) => {
        const newYearPlanId = response.data?.id;

        if (yearPlan.plans && yearPlan.plans.length > 0) {
          for (const plan of yearPlan.plans) {
            const updatedSubjectPlan = {
              yearPlanId: newYearPlanId,
              planId: plan.planId,
              fromDate: plan.fromDate,
              toDate: plan.toDate,
            };

            saveSubjectPlanInYearPlan(updatedSubjectPlan, {
              onSuccess: () => {
                queryClient.invalidateQueries("yearPlans");
              },
            });
          }
        }

        history.push(`/arsplan/${newYearPlanId}`);
      },
    });
  }

  return (
    <tr className={styles.row}>
      <td className={freezeClassName}>
        <NavLink to={`/arsplan/${yearPlan.id}`}>
          {yearPlan.title || "-"}
        </NavLink>
      </td>

      <td>{yearPlan.year ? `${yearPlan.year} - ${yearPlan.year + 1}` : ""}</td>

      <td className={styles.freezeSubject}>
        {yearPlan.subject.split(";").map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        )) || " "}
      </td>

      <td>{yearPlan.grade ? `${yearPlan.grade}. trinn` : ""}</td>

      <td>{new Date(yearPlan.updatedAt).toLocaleDateString("en-GB")}</td>

      <td className={styles.icons}>
        <button title="Åpne" onClick={handleDisplayItem}>
          <IconEye />
        </button>

        {displaySharedOnly ? null : (
          <button title="Del" onClick={handleShareButtonClick}>
            <IconShare />
          </button>
        )}

        <button title="Dupliser" onClick={handleDuplicatePlan}>
          <IconDuplicate />
        </button>

        {displaySharedOnly ? null : (
          <button title="Slett" onClick={handleDeleteItem}>
            <IconTrash />
          </button>
        )}
      </td>
    </tr>
  );
};

export default YearPlanTableItem;
