import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./Label.module.css";

import { FiX } from "../../../common/Icons/Icons";

const Label = ({ label }) => {
  const { t } = useTranslation("subjectPlan");
  const { watch, setValue } = useFormContext();

  const selectedLabelsArray = watch("labels")?.split(",");

  const isChecked = useMemo(
    () => !!selectedLabelsArray?.includes(label),
    [selectedLabelsArray, label]
  );

  function toggleLabel(label) {
    let updatedLabelsArray = [];

    if (isChecked) {
      updatedLabelsArray = selectedLabelsArray.filter((item) => item !== label);
    } else {
      updatedLabelsArray = selectedLabelsArray.concat([label]);
    }

    setValue("labels", updatedLabelsArray.filter(Boolean).sort().join(","), {
      shouldDirty: true,
    });
  }

  return (
    <label
      htmlFor={label}
      className={`${styles.label}
              ${isChecked && styles.checked}
          `}
    >
      <input
        type="checkbox"
        id={label}
        value={label}
        checked={selectedLabelsArray?.includes(label)}
        onChange={(e) => toggleLabel(e.target.value)}
      />

      <span className={styles.label__title}>
        {t(`labelTags.tags.${label}`)}
      </span>
      <span className={styles.icon} aria-hidden={true}>
        <FiX />
      </span>
    </label>
  );
};

export default Label;
