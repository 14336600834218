import { useMutation, useQueryClient } from "react-query";
import yearPlanAPI from "../../../api/yearPlanAPI";

export const useSaveYearPlan = () => {
  const queryClient = useQueryClient();

  return useMutation(yearPlanAPI.updateYearPlan, {
    onSuccess: () => queryClient.invalidateQueries("yearPlans"),
    onError: (error) => console.log("Saving error: ", error),
  });
};
