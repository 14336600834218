import { useMemo } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectAuthUserId } from "../../auth/AuthSlice";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useMySubjectPlans = () => {
  const userId = useSelector(selectAuthUserId);
  const query = useQuery(["plans", "my"], subjectPlanAPI.fetchPlans);

  return {
    ...query,
    data: useMemo(
      () => query.data?.filter((item) => item.userId === userId),
      [query.data, userId]
    ),
  };
};
