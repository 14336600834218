import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Table.module.css";

import TableItem from "./TableItem";
import Select from "../_shared/Select/Select";
import Spinner from "../_shared/Spinner/Spinner";
import Error from "../_shared/Error/Error";

const Table = ({ plansData, showShareModal, displaySharedOnly }) => {
  const [sortKey, setSortKey] = useState("updatedAt");
  const [sortAscending, setSortAscending] = useState(false);
  const [subjectFilter, setSubjectFilter] = useState("Alle");
  const [gradeFilter, setGradeFilter] = useState("Alle");
  const [labelFilter, setLabelFilter] = useState("Alle");

  const { t } = useTranslation("common");

  const { data: plans, isLoading, isError } = plansData;

  function getSortMethod(key) {
    if (key === "grade")
      return (a, b) => {
        return sortAscending ? a[key] - b[key] : b[key] - a[key];
      };

    return (a, b) => {
      if (a[key] < b[key]) return sortAscending ? -1 : 1;
      else if (a[key] > b[key]) return sortAscending ? 1 : -1;
      return 0;
    };
  }

  function sortBy(key) {
    if (key !== sortKey) setSortKey(key);
    else setSortAscending((prev) => !prev);
  }

  function displayArrowWhenSortingBy(key) {
    return (
      <span className={key === sortKey ? styles.visible : ""}>
        {sortAscending ? "↑" : "↓"}
      </span>
    );
  }

  function getFilterMethod() {
    return (element) =>
      (subjectFilter === "Alle" ||
        element.subject?.split(";")?.some((item) => item === subjectFilter)) &&
      (gradeFilter === "Alle" ||
        element.grade?.split(";")?.some((item) => item === gradeFilter)) &&
      (labelFilter === "Alle" || element.labels?.includes(labelFilter));
  }

  function handleFilterChange(key, value) {
    switch (key) {
      case "subject":
        setSubjectFilter(value);
        break;
      case "grade":
        setGradeFilter(value);
        break;
      case "labels":
        setLabelFilter(value);
        break;
      default:
        return;
    }
  }

  return (
    <section className={styles.container}>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Error />
      ) : plans ? (
        <>
          <article className={styles.selectors__container}>
            <Select
              name={t("subject")}
              options={[
                "Alle",
                ...new Set(
                  plans
                    ?.map((item) => item.subject.split(";"))
                    .reduce((acc, value) => acc.concat(value), [])
                    .filter(Boolean)
                    // Sorts subjects alphabetically but puts "" at the end
                    .sort((a, b) => (a < b && a === "" ? 1 : -1))
                ),
              ]}
              id={t("subject")}
              currentValue={subjectFilter}
              handleChange={(value) => handleFilterChange("subject", value)}
            />
            <Select
              name={t("grade")}
              options={[
                "Alle",
                ...new Set(
                  plans
                    ?.map((item) => item.grade.split(";"))
                    .reduce((acc, value) => acc.concat(value), [])
                    .filter(Boolean)
                    // Sorts numbers ascending but puts 0 at the end
                    .sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b))
                ),
              ]}
              id={t("grade")}
              currentValue={gradeFilter}
              handleChange={(value) => handleFilterChange("grade", value)}
            />
            <Select
              name={t("labels")}
              options={[
                "Alle",
                ...new Set(
                  plans
                    ?.map((item) => item.labels?.split(","))
                    .reduce((acc, value) => acc.concat(value), [])
                    .filter((item) => item !== undefined && item !== "")
                    .sort()
                ),
              ]}
              id={t("labels")}
              currentValue={labelFilter}
              handleChange={(value) => handleFilterChange("labels", value)}
            />
          </article>
          <article className={styles.content}>
            <div className={styles.insetShadow} aria-hidden="true" />

            <section className={styles.table__container}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.freeze}>
                      <button
                        className={styles.sortButton}
                        onClick={() => sortBy("title")}
                        title={`sorter basert på ${t("title")}`}
                      >
                        {t("title")}
                        {displayArrowWhenSortingBy("title")}
                      </button>
                    </th>

                    <th>{t("subject")}</th>

                    <th>
                      <button
                        className={styles.sortButton}
                        onClick={() => sortBy("grade")}
                        title={`sorter basert på ${t("grade")}`}
                      >
                        {t("grade")}
                        {displayArrowWhenSortingBy("grade")}
                      </button>
                    </th>

                    <th>{t("labels")}</th>

                    <th>
                      <button
                        className={styles.sortButton}
                        onClick={() => sortBy("updatedAt")}
                        title={`sorter basert på ${t("updatedAt")}`}
                      >
                        {t("updatedAt")}
                        {displayArrowWhenSortingBy("updatedAt")}
                      </button>
                    </th>

                    <th>{t("actions")}</th>
                  </tr>
                </thead>

                <tbody>
                  {plans &&
                    JSON.parse(JSON.stringify(plans))
                      .filter(getFilterMethod())
                      .sort(getSortMethod(sortKey))
                      .map((item, index) => (
                        <TableItem
                          key={index}
                          data={item}
                          showShareModal={showShareModal}
                          freezeClassName={styles.freeze}
                          displaySharedOnly={displaySharedOnly}
                        />
                      ))}
                </tbody>
              </table>
            </section>
          </article>
        </>
      ) : null}

      {plans?.filter(getFilterMethod()).length < 1 && (
        <h2 className={styles.nothingToShow}>{t("noPlansToShow")}</h2>
      )}
    </section>
  );
};

export default Table;
