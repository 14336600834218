import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { hotjar } from "react-hotjar";

import { WebSocketContext } from "./features/socket/SocketContext";
import { useMyUser } from "./features/user/hooks/useMyUser";
import {
  keepRefreshingJwt,
  selectAuthUserId,
  selectLoginToken,
} from "./features/auth/AuthSlice";

import Home from "./components/Home/Home";
import NavBar from "./components/_shared/NavBar/NavBar";
import YearPlans from "./components/YearPlans/YearPlans";
import SubjectPlans from "./components/SubjectPlans/SubjectPlans";
import YearPlan from "./components/YearPlan/YearPlan";
import NewPlan from "./components/SubjectPlan/NewPlan";
import EditPlan from "./components/SubjectPlan/EditPlan";
import Toolbox from "./components/Toolbox/Toolbox";
import DisplayPlan from "./components/SubjectPlan/DisplayPlan";
import Welcome from "./components/Welcome/Welcome";
import FeideLoginCode from "./features/auth/FeideLoginCode";
import NotFound from "./components/_shared/NotFound/NotFound";
import SubjectPlanAccessTake from "./components/SubjectPlan/SubjectPlanAccessTake";
import YearPlanAccessTake from "./components/YearPlan/YearPlanAccessTake";
import PrivacyBox from "./components/_shared/Modals/PrivacyBox";
import NotificationContainer from "./components/_shared/NotificationContainer/NotificationContainer";

// import { ReactQueryDevtools } from "react-query/devtools";

function App() {
  const [shouldAskAboutPrivacy, setShouldAskAboutPrivacy] = useState(false);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const myUser = useMyUser();
  const jwt = useSelector(selectLoginToken);
  const userId = useSelector(selectAuthUserId);
  const webSocket = useContext(WebSocketContext);

  useEffect(() => {
    if (jwt) webSocket.init(jwt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  useEffect(() => {
    const hasAcceptedHotjar = localStorage.getItem("hotjar-accepted");

    if (hasAcceptedHotjar === null) {
      setShouldAskAboutPrivacy(true);
    } else if (hasAcceptedHotjar === "true") {
      hotjar.initialize(2507672, 6);
    }
  }, []);

  useEffect(() => {
    const prefferedLanguage = myUser?.data?.language;
    if (prefferedLanguage) i18n.changeLanguage(prefferedLanguage);
  }, [i18n, myUser?.data]);

  useEffect(() => {
    dispatch(keepRefreshingJwt());
  }, [dispatch]);

  function handleAcceptHotjar() {
    try {
      localStorage.setItem("hotjar-accepted", "true");
      setShouldAskAboutPrivacy(false);
    } catch (error) {
      console.log("Failed setting local storage for hotjar-accepted", error);
      setShouldAskAboutPrivacy(false);
    }
  }

  function handleDeclineHotjar() {
    try {
      localStorage.setItem("hotjar-accepted", "false");
      setShouldAskAboutPrivacy(false);
    } catch (error) {
      console.log("Failed setting local storage for hotjar-accepted", error);
      setShouldAskAboutPrivacy(false);
    }
  }

  return (
    <>
      {userId ? (
        <>
          <NavBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/framside" component={Home} />
            <Route path="/arsplanar" component={YearPlans} />
            <Route path="/temaplanar" component={SubjectPlans} />
            <Route path="/arsplan/:id" component={YearPlan} />
            <Route exact path="/verktoykasse" component={Toolbox} />
            <Route exact path="/temaplan/ny" component={NewPlan} />
            <Route exact path="/temaplan/ny/:id" component={NewPlan} />
            <Route exact path="/temaplan/rediger/:id" component={EditPlan} />
            <Route exact path="/temaplan/visning/:id" component={DisplayPlan} />
            <Route
              exact
              path="/plan/share/:token"
              component={SubjectPlanAccessTake}
            />
            <Route
              exact
              path="/yearplan/share/:token"
              component={YearPlanAccessTake}
            />

            <Route component={NotFound} />
          </Switch>

          {/* <ReactQueryDevtools /> */}
        </>
      ) : (
        <Switch>
          <Route path="/oauth_callback" component={FeideLoginCode} />
          <Welcome />
        </Switch>
      )}

      {shouldAskAboutPrivacy ? (
        <PrivacyBox onYes={handleAcceptHotjar} onNo={handleDeclineHotjar} />
      ) : null}
      <NotificationContainer />
    </>
  );
}

export default App;
