import { useMutation, useQueryClient } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useSaveSubjectPlan = () => {
  const queryClient = useQueryClient();

  return useMutation(subjectPlanAPI.updatePlan, {
    // When mutate is called:
    onMutate: async (newPlan) => {
      if (newPlan.id) {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["plans", newPlan.id]);
        // Snapshot the previous value
        const previousPlan = queryClient.getQueryData(["plans"]);
        // Optimistically update to the new value
        queryClient.setQueryData(["plans", newPlan.id], newPlan);
        // Return a context with the previous and new todo
        return { previousPlan, newPlan };
      }
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newPlan, context) => {
      console.log("Saving error: ", error);
      queryClient.setQueryData(
        ["plans", context.newPlan.id],
        context.previousPlan
      );
    },
    // Always refetch after error or success:
    onSettled: () => queryClient.invalidateQueries("plans"),
  });
};
