import { useMutation, useQueryClient } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useSaveSubjectPlanAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (props) => subjectPlanAPI.savePlanAccess(props.data, props.id),
    {
      onSuccess: () => queryClient.invalidateQueries("plans"),
      onError: (err) => console.log("share access update error: ", err),
    }
  );
};
