import { useMutation } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const usePatchSubjectPlan = () => {
  return useMutation(subjectPlanAPI.patchPlan, {
    onError: (error) => {
      console.log("Error while patching subject plan: ", error);
    },
  });
};
