import { useMutation, useQueryClient } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useDeleteSubjectPlan = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => subjectPlanAPI.deletePlan(id), {
    onSuccess: () => queryClient.invalidateQueries("plans"),
  });
};
