import { useTranslation } from "react-i18next";
import styles from "./Select.module.css";

import { ChevronDown } from "../../../common/Icons/Icons.jsx";

const Select = ({ name, id, options = [], currentValue, handleChange }) => {
  const { t } = useTranslation("subjectPlan");

  return (
    <section className={styles.container}>
      <label htmlFor={id} className={styles.label}>
        {name}
      </label>
      <select
        name={name}
        id={id}
        value={currentValue}
        onChange={(e) => handleChange(e.target.value)}
        className={styles.select}
      >
        {options.map((item, index) => (
          <option key={`${item}-${index}`} value={item}>
            {name === t("common:labels") && item !== "Alle"
              ? t([`subjectPlan:labelTags.tags.${item}`, `${item} (utdatert)`]) // The second option is a a fallback for old labels that don't exist anymore
              : name === t("common:subject") && item === ""
              ? t("common:noSubjectPlanSelected")
              : name === t("common:grade") && item === 0
              ? t("common:noGradeSelected")
              : name === t("yearPlan:schoolYear") && item !== "Alle"
              ? `${item} - ${item + 1}`
              : item}
          </option>
        ))}
      </select>
      <ChevronDown className={styles.dropdownArrow} />
    </section>
  );
};

export default Select;
