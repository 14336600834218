import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./FormContextSelect.module.css";

import { ChevronDown } from "../../../common/Icons/Icons";

const FormContextSelect = ({ label, id, name, options = [] }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <section className={styles.container}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>

      <select name={name} id={id} className={styles.select} {...register(name)}>
        {options.map((item, index) => (
          <option key={`${item}-${index}`} value={item}>
            {label === t("yearPlan:schoolYear")
              ? `${item} - ${item + 1}`
              : item}
          </option>
        ))}
      </select>
      <ChevronDown className={styles.dropdownArrow} />
    </section>
  );
};

export default FormContextSelect;
