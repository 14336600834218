import { fetchData, patchData } from "./fetch";

const fetchMyUser = async () => await fetchData("core/user/me");

const patchMyUser = async (data) => patchData("core/user", data);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchMyUser,
  patchMyUser,
};
