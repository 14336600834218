import { useMutation, useQueryClient } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useDeleteSubjectPlanAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(subjectPlanAPI.deletePlanAccess, {
    onSuccess: () => queryClient.invalidateQueries("plans"),
    onError: (error) => console.log("Delete acccess error: ", error),
  });
};
