import { url2data } from "./fetch";

const feideLogout = async () => {
  return url2data(`core/auth/logout`, {});
};

const getFeideLoginUrl = async () => {
  return url2data(`core/feide/url`);
};

const verifyFeideCode = async (code) => {
  return url2data(`core/feide/code`, { code });
};

const refresh = async () => {
  return url2data(`core/auth/refresh`, {});
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  feideLogout,
  getFeideLoginUrl,
  verifyFeideCode,
  refresh,
};
