import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { addYears } from "date-fns";
import { useQueryClient } from "react-query";
import styles from "./EditYearPlanModal.module.css";

import { useSaveYearPlan } from "../../../features/yearPlan/hooks/useSaveYearPlan";
import { useSaveSubjectPlanInYearPlan } from "../../../features/yearPlan/hooks/useSaveSubjectPlanInYearPlan";
import { useYearPlan } from "../../../features/yearPlan/hooks/useYearPlan";
import { useDeleteYearPlan } from "../../../features/yearPlan/hooks/useDeleteYearPlan";
import { useBlockBodyScrollOnMount } from "../../../common/hooks/useBlockBodyScrollOnMount";
import subjects from "../../../common/subjects.json";

import FormContextSelect from "../Select/FormContextSelect";
import Spinner from "../Spinner/Spinner";

const EditYearPlanModal = ({ planId, closeModal }) => {
  const [yearOptions, setYearOptions] = useState([]);
  const { t } = useTranslation("yearPlan");
  const history = useHistory();
  const queryClient = useQueryClient();

  useBlockBodyScrollOnMount();

  const methods = useForm();
  const { data: selectedPlan, isLoading } = useYearPlan(planId);
  const { mutate: savePlan, isLoading: isSavingPlan } = useSaveYearPlan();
  const { mutate: saveSubjectPlanInYearPlan } = useSaveSubjectPlanInYearPlan();
  const { mutate: deletePlan } = useDeleteYearPlan();

  // Generate an array with years from 2020 to the current year + 2
  useEffect(() => {
    const schoolYearNow = getCurrentSchoolYear();
    const yearOptions = [];

    for (let i = 2021; i <= schoolYearNow + 2; i++) {
      yearOptions.push(i);
    }

    setYearOptions(yearOptions);
  }, []);

  // Reset form with data
  useEffect(() => {
    if (!planId) {
      methods.reset({
        year: getCurrentSchoolYear(),
        subject: subjects[0]?.name,
        grade: 1,
        title: "",
      });
    } else {
      methods.reset({
        year: selectedPlan.year,
        subject: selectedPlan.subject,
        grade: selectedPlan.grade,
        title: selectedPlan.title,
      });
    }
  }, [planId, methods, selectedPlan]);

  function getCurrentSchoolYear() {
    const monthNow = new Date().getMonth();
    const yearNow = new Date().getFullYear();
    const schoolYearNow = monthNow < 8 ? yearNow - 1 : yearNow;
    return schoolYearNow;
  }

  function handleDeletePlan() {
    const decision = window.confirm(
      t("common:confirmations.removeSubjectPlan")
    );

    if (decision)
      deletePlan(planId, {
        onSuccess: () => history.push("/arsplanar"),
      });
  }

  function onSubmit(data) {
    const post = {
      year: Number(data.year),
      subject: data.subject,
      grade: Number(data.grade),
      title: data.title,
    };
    if (planId) post.id = planId;

    savePlan(post, {
      onSuccess: (response) => {
        // Change all subject plans' from/to dates if year is updated
        if (
          planId &&
          Number(data.year) !== selectedPlan.year &&
          selectedPlan?.plans?.length > 0
        ) {
          const yearDifference = Number(data.year) - selectedPlan.year;

          for (const subjectPlan of selectedPlan.plans) {
            const updatedSubjectPlan = {
              id: subjectPlan.id,
              fromDate: addYears(
                new Date(subjectPlan.fromDate),
                yearDifference
              ),
              toDate: addYears(new Date(subjectPlan.toDate), yearDifference),
            };

            saveSubjectPlanInYearPlan(updatedSubjectPlan, {
              onSuccess: () => queryClient.invalidateQueries("yearPlans"),
            });
          }
        }

        closeModal();
        history.push(`/arsplan/${response.data?.id}`);
      },
    });
  }

  return (
    <article className={styles.container}>
      <div
        className={styles.background}
        aria-hidden="true"
        onClick={closeModal}
      />

      <FormProvider {...methods}>
        <form className={styles.box} onSubmit={methods.handleSubmit(onSubmit)}>
          <h2 className={styles.header}>
            {planId ? t("editPlan") : t("makeNewPlan")}
          </h2>

          {planId && isLoading ? (
            <article className={styles.spinner__container}>
              <Spinner />
            </article>
          ) : (
            <>
              <section className={styles.selector__container}>
                <FormContextSelect
                  label={t("schoolYear")}
                  name="year"
                  id="year"
                  options={yearOptions}
                />
              </section>

              <section
                className={`${styles.selector__container} ${styles.double}`}
              >
                <FormContextSelect
                  label={t("subject")}
                  name="subject"
                  id="subject"
                  options={subjects.map((item) => item.name)}
                />
                <FormContextSelect
                  label={t("grade")}
                  name="grade"
                  id="grade"
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </section>

              <section className={styles.input__container}>
                <label htmlFor="title">{t("planName-description")}</label>
                <input
                  name={t("planName-description")}
                  id="title"
                  maxLength={250}
                  autoComplete="off"
                  required
                  {...methods.register("title")}
                />
              </section>

              <section className={styles.buttons__container}>
                <section className={styles.saveCancel}>
                  <button type="submit" className={styles.save}>
                    {isSavingPlan ? t("loading") : t("savePlan")}
                  </button>

                  <button
                    type="button"
                    className={styles.cancel}
                    onClick={closeModal}
                  >
                    {t("cancel")}
                  </button>
                </section>

                {planId && (
                  <button
                    type="button"
                    className={styles.delete}
                    onClick={handleDeletePlan}
                  >
                    {t("deletePlan")}
                  </button>
                )}
              </section>
            </>
          )}
        </form>
      </FormProvider>
    </article>
  );
};

export default EditYearPlanModal;
