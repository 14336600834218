import { updateData, fetchData, deleteData, patchData } from "./fetch";

const fetchPlans = async () => fetchData(`core/plan`);

const fetchPlan = async (id) => {
  if (!id) {
    throw new Error("No id present");
  }
  return fetchData(`core/plan/${id}`);
};

const updatePlan = async (data) => updateData(data, `core/plan`);
const patchPlan = async ({ planId, data }) =>
  patchData(`core/plan/${planId}`, data);

const deletePlan = async (id) => deleteData(`core/plan/${id}`);

const savePlanAccess = async (data, planId) =>
  updateData(data, `core/plan/share/${planId}`);

const updatePlanAccess = async (data) => updateData(data, `core/plan/share`);

const takePlanAccess = async (accessToken) =>
  fetchData(`core/plan/share/${accessToken}`);

const deletePlanAccess = async (accessId) =>
  deleteData(`core/plan/share/${accessId}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchPlans,
  fetchPlan,
  updatePlan,
  patchPlan,
  deletePlan,
  savePlanAccess,
  updatePlanAccess,
  takePlanAccess,
  deletePlanAccess,
};
