import { useState } from "react";
import { NavLink, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./YearPlans.module.css";

import { useSharedYearPlans } from "../../features/yearPlan/hooks/useSharedYearPlans";
import { useMyYearPlans } from "../../features/yearPlan/hooks/useMyYearPlans";

import EditYearPlanModal from "../_shared/Modals/EditYearPlanModal";
import Footer from "../_shared/Footer/Footer";
import YearPlansTable from "./YearPlansTable";
import ShareModal from "../_shared/Modals/ShareModal";
import YearPlansCompare from "../YearPlansCompare/YearPlansCompare";

const YearPlans = () => {
  const { t } = useTranslation("yearPlan");
  const [isNewPlanModalOpened, setIsNewPlanModelOpened] = useState(false);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const myPlansData = useMyYearPlans();
  const sharedPlansData = useSharedYearPlans();

  function handleAddPlan() {
    setIsNewPlanModelOpened(true);
  }

  function handleShowShareModal(planId) {
    setSelectedPlanId(planId);
    setIsShareModalOpen(true);
  }

  return (
    <>
      <main className={styles.main}>
        <section className={styles.header}>
          <h1>{t("yearPlans")}</h1>

          <button className={styles.addPlan} onClick={handleAddPlan}>
            {t("makeNewPlan")}
          </button>
        </section>

        <nav className={styles.nav}>
          <NavLink to="/arsplanar" exact activeClassName={styles.activeLink}>
            {t("myPlans")}
          </NavLink>

          <NavLink
            to="/arsplanar/delt-med-meg"
            exact
            activeClassName={styles.activeLink}
          >
            {t("sharedWithMe")}
          </NavLink>

          <NavLink
            to="/arsplanar/samanlikna"
            exact
            activeClassName={styles.activeLink}
          >
            {t("comparePlans")}
          </NavLink>
        </nav>

        <Switch>
          <Route path="/arsplanar/delt-med-meg">
            <YearPlansTable
              plansData={sharedPlansData}
              showShareModal={handleShowShareModal}
              displaySharedOnly
            />
          </Route>

     s     <Route path="/arsplanar/samanlikna">
            <YearPlansCompare />
          </Route>

          <Route path="/arsplanar">
            <YearPlansTable
              plansData={myPlansData}
              showShareModal={handleShowShareModal}
            />
          </Route>
        </Switch>

        {isNewPlanModalOpened && (
          <EditYearPlanModal
            closeModal={() => setIsNewPlanModelOpened(false)}
          />
        )}

        {isShareModalOpen && (
          <ShareModal
            closeModal={() => setIsShareModalOpen(false)}
            planId={selectedPlanId}
            planType="year"
          />
        )}
      </main>
      <Footer />
    </>
  );
};

export default YearPlans;
