import { useTranslation } from "react-i18next";
import styles from "./LoginSpinner.module.css";

import Spinner from "./Spinner";

const LoginSpinner = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Spinner />
      <h1 className={styles.text}>{t("loggingIn")}</h1>
    </>
  );
};

export default LoginSpinner;
