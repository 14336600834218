import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./YearPlansCompare.module.css";

import { useAllYearPlans } from "../../features/yearPlan/hooks/useAllYearPlans";

import Error from "../_shared/Error/Error";
import Spinner from "../_shared/Spinner/Spinner";
import SearchSelect from "./SearchSelect";
import CompareCalendar from "./CompareCalendar";
import EditSubjectPlanInYearPlanModal from "../_shared/Modals/EditSubjectPlanInYearPlanModal";

const YearPlansCompare = () => {
  const [selectedYearPlanId, setSelectedYearPlanId] = useState(null);
  const [newSubjectPlanFromDate, setNewSubjectPlanFromDate] = useState(null);
  const [selectedSubjectPlanId, setSelectedSubjectPlanId] = useState(null);
  const [isEditSubjectPlanModalOpen, setIsEditSubjectPlanModalOpen] =
    useState(false);

  const [selectedPlanIds, setSelectedPlanIds] = useState([
    null,
    null,
    null,
    null,
  ]);

  const { t } = useTranslation("yearPlan");
  const { data: yearPlans, isLoading, isError } = useAllYearPlans();

  const yearPlansOptions = useMemo(
    () =>
      yearPlans?.length
        ? yearPlans.map((yearPlan) => ({
            label: `${yearPlan.title || "–"} (${
              yearPlan.year ? `${yearPlan.year}-${yearPlan.year + 1}` : "–"
            }, ${yearPlan.subject || "–"}, ${
              yearPlan.grade ? yearPlan.grade + ".trinn" : "–"
            })`,
            value: yearPlan.id,
          }))
        : [],
    [yearPlans]
  );

  function handleSelectChange(option, selector) {
    const selectorNumber = Number(selector.name);
    const planId = option?.value || null;

    setSelectedPlanIds((prev) => {
      const idList = [...prev];
      idList[selectorNumber - 1] = planId;
      return idList;
    });
  }

  function handleAddSubjectPlan(yearPlanId, fromDate) {
    setSelectedYearPlanId(yearPlanId);
    if (fromDate) setNewSubjectPlanFromDate(fromDate);
    setIsEditSubjectPlanModalOpen(true);
  }

  function handleEditSubjectPlan(yearPlanId, subjectPlanId) {
    setSelectedYearPlanId(yearPlanId);
    if (subjectPlanId) setSelectedSubjectPlanId(subjectPlanId);
    setIsEditSubjectPlanModalOpen(true);
  }

  function closeSubjectPlanModal() {
    setIsEditSubjectPlanModalOpen(false);
    setNewSubjectPlanFromDate(null);
    setSelectedSubjectPlanId(null);
    setSelectedYearPlanId(null);
  }

  return (
    <>
      <section className={styles.container}>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Error />
        ) : yearPlans ? (
          <>
            <section className={styles.selects}>
              <SearchSelect
                name={t("chooseYearPlan")}
                id={"1"}
                options={yearPlansOptions}
                onChange={handleSelectChange}
              />

              <SearchSelect
                name={t("chooseYearPlan")}
                id={"2"}
                options={yearPlansOptions}
                onChange={handleSelectChange}
              />

              <SearchSelect
                name={t("chooseYearPlan")}
                id={"3"}
                options={yearPlansOptions}
                onChange={handleSelectChange}
              />

              <SearchSelect
                name={t("chooseYearPlan")}
                id={"4"}
                options={yearPlansOptions}
                onChange={handleSelectChange}
              />
            </section>

            <CompareCalendar
              selectedPlanIds={selectedPlanIds}
              addSubjectPlan={handleAddSubjectPlan}
              editSubjectPlan={handleEditSubjectPlan}
            />
          </>
        ) : (
          <h2 className={styles.nothingToShow}>{t("noYearPlansToShow")}</h2>
        )}
      </section>

      {isEditSubjectPlanModalOpen && (
        <EditSubjectPlanInYearPlanModal
          yearPlan={
            yearPlans?.find((plan) => plan.id === selectedYearPlanId) || null
          }
          planId={selectedSubjectPlanId}
          selectedDate={newSubjectPlanFromDate}
          closeModal={closeSubjectPlanModal}
        />
      )}
    </>
  );
};

export default YearPlansCompare;
