import { useTranslation } from "react-i18next";
import styles from "./Activity.module.css";
import { convertTextToLinks } from "../../common/functions";

const Activity = ({ activity, index, isResearchPhase }) => {
  const { t } = useTranslation("subjectPlan");

  return (
    <section
      className={`${styles.container} ${
        isResearchPhase ? styles.researchPhase : ""
      }`}
    >
      {isResearchPhase ? (
        <>
          <h3 className={styles.title}>
            {`${t("general.session")} ${index + 1}`}
          </h3>

          {!!activity.title && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.activity-title")}
              </h4>
              <p>{activity.title}</p>
            </>
          )}

          {!!activity.type && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.activity-type-short")}
              </h4>
              <p>{activity.type}</p>
            </>
          )}

          {!!activity.resources && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.activity-resources-short")}
              </h4>
              <p>{convertTextToLinks(activity.resources)}</p>
            </>
          )}

          {!!activity.instructions && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.activity-instructions")}
              </h4>
              <p>{activity.instructions}</p>
            </>
          )}

          {!!activity.homework && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.activity-homework")}
              </h4>
              <p>{activity.homework}</p>
            </>
          )}
        </>
      ) : (
        <>
          {!!activity.instructions && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.startPhase-knowledge")}
              </h4>
              <p>{activity.instructions}</p>
            </>
          )}

          {!!activity.type && (
            <>
              <h4 className={styles.section__header}>{t("labels.activity")}</h4>
              <p>{activity.type}</p>
            </>
          )}

          {!!activity.resources && (
            <>
              <h4 className={styles.section__header}>
                {t("labels.activity-resources-short")}
              </h4>
              <p>{convertTextToLinks(activity.resources)}</p>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default Activity;
