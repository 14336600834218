import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import subjectPlanAPI from "../../../api/subjectPlanAPI";
import { selectJwtTimestamp, doJwtRefresh } from "../../auth/AuthSlice";

export const useSubjectPlan = (id) => {
  const dispatch = useDispatch();
  const jwtTimestamp = useSelector(selectJwtTimestamp);

  return useQuery(
    ["plans", id],
    () => {
      if (Date.now() - jwtTimestamp < 1000 * 60 * 1) {
        return subjectPlanAPI.fetchPlan(id);
      } else {
        return dispatch(doJwtRefresh()).then(() =>
          subjectPlanAPI.fetchPlan(id)
        );
      }
    },
    {
      enabled: !!id,
    }
  );
};
