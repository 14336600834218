import { endOfMonth, eachMonthOfInterval } from "date-fns";
import styles from "./YearCalendar.module.css";
import CalendarQuarter from "./CalendarQuarter";

const YearCalendar = ({ yearPlan, addSubjectPlan, editSubjectPlan }) => {
  const { year } = yearPlan;

  const yearInterval = {
    start: new Date(year, 7),
    end: endOfMonth(new Date(year + 1, 6)),
  };

  const months = eachMonthOfInterval(yearInterval);

  return (
    <section>
      <article className={styles.quarterContainer}>
        <CalendarQuarter
          months={months.slice(0, 3)}
          yearPlan={yearPlan}
          addSubjectPlan={addSubjectPlan}
          editSubjectPlan={editSubjectPlan}
        />
        <CalendarQuarter
          months={months.slice(3, 6)}
          yearPlan={yearPlan}
          addSubjectPlan={addSubjectPlan}
          editSubjectPlan={editSubjectPlan}
        />
        <CalendarQuarter
          months={months.slice(6, 9)}
          yearPlan={yearPlan}
          addSubjectPlan={addSubjectPlan}
          editSubjectPlan={editSubjectPlan}
        />
        <CalendarQuarter
          months={months.slice(9, 12)}
          yearPlan={yearPlan}
          addSubjectPlan={addSubjectPlan}
          editSubjectPlan={editSubjectPlan}
        />
      </article>
    </section>
  );
};

export default YearCalendar;
