import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CollapseDescription.module.css";

import { ChevronDown } from "../../../common/Icons/Icons";
import TranslatedText from "../../_shared/Translation/TranslatedText";

const CollapseDescription = ({
  translationKeyForHeader,
  translationKeyForContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <button
        type="button"
        className={styles.expandButton}
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {t(translationKeyForHeader)}
        <ChevronDown
          className={`${styles.dropdownArrow} ${isOpen ? styles.open : ""}`}
        />
      </button>

      {isOpen && (
        <section className={styles.content__container}>
          <TranslatedText translationKey={translationKeyForContent} />
        </section>
      )}
    </section>
  );
};

export default CollapseDescription;
