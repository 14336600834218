import { useMemo, useCallback } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";

const RichTextDisplay = ({ value }) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const content = useMemo(
    () =>
      tryParsingJSON(value) || [
        {
          type: "paragraph",
          children: [{ text: value || "" }],
        },
      ],
    [value]
  );

  function tryParsingJSON(string) {
    try {
      const parsed = JSON.parse(string);
      if (parsed && typeof parsed === "object") return parsed;
    } catch (e) {}
    return false;
  }

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  return (
    <Slate editor={editor} value={content} key={value}>
      <Editable
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

export default RichTextDisplay;

const Leaf = (props) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? "bold" : "normal",
      }}
    >
      {props.children}
    </span>
  );
};

const DefaultElement = (props) => {
  return <p {...props.attributes}>{props.children}</p>;
};
