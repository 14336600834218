import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { doVerifyFeideCode, selectAuth } from "./AuthSlice";
import { useHistory } from "react-router-dom";
import LoginSpinner from "../../components/_shared/Spinner/LoginSpinner";

const Error = ({ error }) => {
  const parseError = (statusCode) => {
    switch (statusCode) {
      case 400:
        return "Ugyldig valideringskode hos Feide.";
      case 502:
        return "Fikk ikke kontakt med Feide.";
      default:
        return "En ukjent feil oppsto.";
    }
  };

  return (
    <section>
      <h3>
        Det oppsto en feil ved innlogging
        <br />
      </h3>
      <p>{parseError(error.statusCode)}</p>
    </section>
  );
};

const FeideLoginCode = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const auth = useSelector(selectAuth);
  const history = useHistory();

  useEffect(() => {
    if (code && !auth.isLoggingIn && !auth.user?.id) {
      dispatch(doVerifyFeideCode(code)).then(() => {
        history.push("/framside");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, code, auth]);

  useEffect(() => {
    if (auth.user?.id) history.push("/framside");
  }, [auth, history]);

  return (
    <section className="content">
      {auth?.loginError ? (
        <Error error={auth.loginError} />
      ) : (
        <>
          <LoginSpinner />
        </>
      )}
    </section>
  );
};

export default FeideLoginCode;
