import { useSelector } from "react-redux";
import {
  eachDayOfInterval,
  getWeek,
  getISODay,
  isSameMonth,
  isWithinInterval,
  isSameDay,
  isSameWeek,
  endOfMonth,
} from "date-fns";
import styles from "./CalendarWeek.module.css";

import { selectAuthUserId } from "../../../features/auth/AuthSlice";
import { NavLink } from "react-router-dom";

const CalendarWeek = ({
  week,
  month,
  yearPlan,
  addSubjectPlan,
  editSubjectPlan,
}) => {
  const days = eachDayOfInterval(week);
  const { plans } = yearPlan;
  const userId = useSelector(selectAuthUserId);

  const weekNumber = getWeek(week.start, {
    weekStartsOn: 1,
    firstWeekContainsDate: 4,
  });

  return (
    <section className={styles.container}>
      <div className={`${styles.day} ${styles.weekNumber}`}>
        <span className={styles.number}>{weekNumber}</span>
      </div>

      {days.map((day, index) => {
        const dayOfWeek = getISODay(day);
        const dayOfMonth = day.getDate();
        const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;
        const isPartOfMonth = isSameMonth(day, month.start);
        const isLastWeekOfMonth = isSameWeek(day, endOfMonth(day));

        const planForDay = plans.find((plan) =>
          isWithinInterval(day, plan.interval)
        );
        const hasPlan = Boolean(planForDay);

        const isFirstDayOfPlanThisWeek =
          !hasPlan || !isPartOfMonth || isWeekend
            ? false
            : isSameDay(day, planForDay.interval.start) || dayOfWeek === 1;

        const shouldDisplayTitle =
          isFirstDayOfPlanThisWeek ||
          (isPartOfMonth && hasPlan && dayOfMonth === 1 && !isWeekend);

        const dayNumberForPlanEnd = hasPlan
          ? getISODay(planForDay.interval.end)
          : 0;

        const isSameWeekAsPlanEnd =
          hasPlan &&
          isSameWeek(day, planForDay.interval.end, { weekStartsOn: 1 });

        const indexOfPlan = plans.indexOf(planForDay);

        const planColor =
          (indexOfPlan + 1) % 3 === 0
            ? "#fb8f67"
            : (indexOfPlan + 1) % 2 === 0
            ? "#ffc2b4"
            : "#f8e16c";

        const daysUntilWeekend = 6 - dayOfWeek;
        const daysUntilEndOfPlan =
          (dayNumberForPlanEnd < 6 ? dayNumberForPlanEnd + 1 : 6) - dayOfWeek;
        const daysUntilEndOfMonth = endOfMonth(day).getDate() - dayOfMonth + 1;

        const titleWidth = isSameWeekAsPlanEnd
          ? isLastWeekOfMonth
            ? daysUntilEndOfPlan < daysUntilEndOfMonth
              ? daysUntilEndOfPlan
              : daysUntilEndOfMonth
            : daysUntilEndOfPlan
          : isLastWeekOfMonth
          ? daysUntilEndOfMonth
          : daysUntilWeekend;

        const hasWriteAccessToYearPlan =
          yearPlan.userId === userId ||
          yearPlan.access.find((item) => item.userId === userId)?.access ===
            "W";

        function onDayClick() {
          if (!isPartOfMonth || isWeekend) return;

          if (hasWriteAccessToYearPlan) {
            if (hasPlan) {
              editSubjectPlan(planForDay.id);
            } else {
              addSubjectPlan(day);
            }
          }
        }

        const buttonTitle = hasWriteAccessToYearPlan
          ? hasPlan
            ? "Rediger temaplan i årsplan"
            : "Legg til temaplan i årsplanen"
          : "";

        const classes = `
        ${styles.day}
        ${styles[`weekDay-${dayOfWeek}`] || ""}
        ${hasWriteAccessToYearPlan ? styles.hasWriteAccessToYearPlan : ""}
        ${isPartOfMonth ? styles.partOfMonth : ""}
        ${hasPlan ? styles.hasPlan : ""}
        `;

        const title = shouldDisplayTitle ? (
          <span
            className={styles.planTitle}
            style={{
              "--titleWidth": titleWidth,
            }}
          >
            {planForDay.plan.title}
          </span>
        ) : null;

        if (
          !isPartOfMonth ||
          isWeekend ||
          (!hasPlan && !hasWriteAccessToYearPlan)
        ) {
          return <div key={`${day}-${index}`} className={classes} />;
        } else if (hasPlan && !hasWriteAccessToYearPlan) {
          return (
            <NavLink
              to={`/temaplan/visning/${planForDay.planId}`}
              key={`${day}-${index}`}
              className={classes}
              style={{
                "--planColor": planColor,
              }}
            >
              {title}
            </NavLink>
          );
        } else {
          return (
            <button
              key={`${day}-${index}`}
              onClick={onDayClick}
              className={classes}
              style={{
                "--planColor": planColor,
              }}
              title={buttonTitle}
            >
              {title}
            </button>
          );
        }
      })}
    </section>
  );
};

export default CalendarWeek;
