import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styles from "./CustomToast.module.css";
import { ChevronDown } from "../../../common/Icons/Icons.jsx";

const CustomToast = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { t } = useTranslation("common");

  return (
    <div className={styles.notificationBox}>
      <button
        className={styles.closeButton}
        onClick={() => toast.remove(props.toast.id)}
      >
        {t("notificationEditingTogether.close")}
      </button>

      <section className={styles.content}>
        <button
          onClick={() => setIsExpanded((prev) => !prev)}
          className={styles.expandButton}
        >
          <span className={styles.title}>
            {t("notificationEditingTogether.title")}
          </span>

          <ChevronDown
            className={`${styles.chevron} ${
              isExpanded ? styles.expanded : ""
            } `}
          />
        </button>

        {isExpanded && (
          <section className={styles.description}>
            {t("notificationEditingTogether.description")}
          </section>
        )}
      </section>
    </div>
  );
};

export default CustomToast;
