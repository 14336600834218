import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  IconArrowDown,
  IconArrowUp,
  IconTrash,
} from "../../../common/Icons/Icons";
import ExpandingTextArea from "../../_shared/Inputs/ExpandingTextArea";
import TranslatedText from "../../_shared/Translation/TranslatedText";
import styles from "./Activities.module.css";
import CollapseDescription from "./CollapseDescription";
import CollapseInput from "./CollapseInput";

const emptyActivity = {
  title: "",
  type: "",
  resources: "",
  instructions: "",
  homework: "",
};

const Activities = ({ methods }) => {
  const { t } = useTranslation("subjectPlan");
  const { register } = useFormContext();

  const activitiesPosition = useRef();

  const {
    fields: activityFields,
    append: appendActivity,
    remove: removeActivity,
    move: moveActivity,
  } = methods;

  function handleAddActivity() {
    appendActivity(emptyActivity);

    setTimeout(() => {
      activitiesPosition.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 300);
  }

  function handleRemoveActivity(id) {
    if (wantsToRemoveActivity()) {
      removeActivity(id);
    }
  }

  function wantsToRemoveActivity() {
    return window.confirm(t("common:confirmations.removeActivity"));
  }

  return (
    <section className={`${styles.activities__container} ${styles.research}`}>
      <h2 className={styles.header}>
        {t("labels.activities-researchPhase-header")}
      </h2>

      <section className={styles.collapseDescriptions__container}>
        <CollapseDescription
          translationKeyForHeader="subjectPlan:descriptions.activities-researchPhase-reading-header"
          translationKeyForContent="subjectPlan:descriptions.activities-researchPhase-reading-content"
        />
        <CollapseDescription
          translationKeyForHeader="subjectPlan:descriptions.activities-researchPhase-writing-header"
          translationKeyForContent="subjectPlan:descriptions.activities-researchPhase-writing-content"
        />
        <CollapseDescription
          translationKeyForHeader="subjectPlan:descriptions.activities-researchPhase-dialogue-header"
          translationKeyForContent="subjectPlan:descriptions.activities-researchPhase-dialogue-content"
        />
      </section>

      {activityFields?.map((item, index) => (
        <section key={`${item.id || 0}-${index}`} className={styles.activity}>
          <section className={styles.header}>
            <h3>
              {t("general.session")} {index + 1}
            </h3>

            {activityFields.length > 1 && (
              <section className={styles.buttons}>
                <div className={styles.orderButtonContainer}>
                  <button
                    type="button"
                    className={`${styles.button} ${styles.orderButton}`}
                    disabled={index === 0}
                    onClick={() => moveActivity(index, index - 1)}
                  >
                    <IconArrowUp />
                  </button>

                  <button
                    type="button"
                    className={`${styles.button} ${styles.orderButton}`}
                    disabled={index === activityFields.length - 1}
                    onClick={() => moveActivity(index, index + 1)}
                  >
                    <IconArrowDown />
                  </button>
                </div>

                <button
                  type="button"
                  onClick={() => handleRemoveActivity(index)}
                  className={`${styles.button} ${styles.button__remove}`}
                  title="Slett aktivitet"
                >
                  <IconTrash />
                </button>
              </section>
            )}
          </section>

          <label htmlFor={`activities.${index}.title`}>
            {t("labels.activity-title")}
          </label>
          <input
            {...register(`activities.${index}.title`)}
            type="text"
            id={`activities.${index}.title`}
            maxLength={250}
            spellCheck="false"
          />

          <label htmlFor={`activities.${index}.type`}>
            {t("labels.describe-activity")}
          </label>
          <ExpandingTextArea
            name={`activities.${index}.type`}
            id={`activities.${index}.type`}
            defaultValue={item.type || ""}
            minHeight={7}
          />

          <label htmlFor={`activities.${index}.resources`}>
            {t("labels.activity-resources")}
          </label>
          <ExpandingTextArea
            name={`activities.${index}.resources`}
            id={`activities.${index}.resources`}
            defaultValue={item.resources || ""}
            minHeight={7}
          />

          <span className={styles.description}>
            <TranslatedText translationKey="subjectPlan:descriptions.activity-researchPhase-resources" />
          </span>

          <label htmlFor={`activities.${index}.instructions`}>
            {t("labels.activity-instructions")}
          </label>
          <ExpandingTextArea
            name={`activities.${index}.instructions`}
            id={`activities.${index}.instructions`}
            defaultValue={item.instructions || ""}
            minHeight={7}
          />

          <span className={styles.description}>
            <TranslatedText translationKey="subjectPlan:descriptions.activity-researchPhase-instructions" />
          </span>

          <CollapseInput
            id={`activities.${index}.homework`}
            label={t("labels.activity-homework")}
            defaultInputValue={item.homework || ""}
            description={
              <TranslatedText translationKey="subjectPlan:descriptions.activity-researchPhase-homework" />
            }
          />
        </section>
      ))}

      <button
        type="button"
        onClick={handleAddActivity}
        className={`${styles.button} ${styles.button__addActivity}`}
        title={t("general.addSession")}
        ref={activitiesPosition}
      >
        {`+ ${t("general.addSession")}`}
      </button>
    </section>
  );
};

export default Activities;
