import { useEffect, useCallback, useState, Fragment, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Prompt, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactEditor, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor } from "slate";
import debounce from "lodash.debounce";
import styles from "./NewPlan.module.css";

import { formatPlanForDatabase } from "../../features/subjectPlan/helpers.js";
import { useSubjectPlan } from "../../features/subjectPlan/hooks/useSubjectPlan.js";
import { usePatchSubjectPlan } from "../../features/subjectPlan/hooks/usePatchSubjectPlan";
import { useSaveSubjectPlan } from "../../features/subjectPlan/hooks/useSaveSubjectPlan.js";
import { useSubjectPlanListener } from "../../features/subjectPlan/hooks/useSubjectPlanListener.js";

import {
  ChevronDown,
  IconCheckMark,
  IconDocument,
} from "../../common/Icons/Icons.jsx";
import ExpandingTextArea from "../_shared/Inputs/ExpandingTextArea.jsx";
import subjectOptions from "../../common/subjects.json";
import Footer from "../_shared/Footer/Footer.jsx";
import TranslatedText from "../_shared/Translation/TranslatedText";
import RichTextArea from "../_shared/Inputs/RichTextArea";

const defaultFormValues = {
  title: "",
  grade: "",
  subject: "",
  curriculumBase: "",
  goals: "",
  reason: "",
  challenges: "",
  concepts: "",
  time: "",
};

const emptyActivity = {
  title: "",
  type: "",
  resources: "",
  instructions: "",
  homework: "",
};

const NewPlan = () => {
  const [previouslyFetchedPlan, setPreviouslyFetchedPlan] = useState(null);
  const history = useHistory();
  const params = useParams();
  const planId = Number(params.id);
  const { t } = useTranslation("subjectPlan");
  const { data: currentSubjectPlan } = useSubjectPlan(planId);

  const {
    mutate: savePlan,
    isLoading: isSaving,
    isError: isSavingError,
  } = useSaveSubjectPlan();

  const {
    mutate: patchPlan,
    isLoading: isPatching,
    isError: isPatchingError,
  } = usePatchSubjectPlan();

  // Start listening to changes on plan made by other users
  useSubjectPlanListener(planId);

  const richTextEditorInstance = useMemo(
    () => withHistory(withReact(createEditor())),
    []
  );

  const methods = useForm({
    defaultValues: currentSubjectPlan || defaultFormValues,
  });
  const { isDirty, dirtyFields, isSubmitting } = methods.formState;

  const watch = methods.watch();
  const { grade, subject } = watch;
  const gradesArray = grade.split(";");
  const subjectsArray = subject.split(";");

  // Update form with new data from server
  useEffect(() => {
    if (
      !currentSubjectPlan ||
      (currentSubjectPlan && !Object.keys(currentSubjectPlan).length)
    )
      return;

    const updateKeys = Object.keys(currentSubjectPlan).filter(
      (key) =>
        !["access", "user", "updatedAt", "userId", "activities"].includes(key)
    );

    // Update form with new data from server, but only for fields that have changed
    updateKeys.forEach((key) => {
      if (previouslyFetchedPlan?.[key] !== currentSubjectPlan[key]) {
        // Deselect the rich text editor if it's content is about to be updated while it's focused.
        // This is to avoid the editor from crashing in case of invalid cursor position.

        if (
          key === "curriculumBase" &&
          ReactEditor.isFocused(richTextEditorInstance)
        ) {
          ReactEditor.deselect(richTextEditorInstance);
        }

        methods.setValue(key, currentSubjectPlan[key]);
      }
    });

    setPreviouslyFetchedPlan(currentSubjectPlan);
  }, [
    currentSubjectPlan,
    methods,
    previouslyFetchedPlan,
    richTextEditorInstance,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((callback) => callback(), 1000),
    []
  );

  // Run auto-save after every change in form when editing an existing plan
  useEffect(() => {
    if (
      currentSubjectPlan &&
      !!Object.keys(dirtyFields).length &&
      !isSaving &&
      !isPatching
    ) {
      debounceCallback(() => patchUpdatedFields());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields, isSaving, isPatching, watch, currentSubjectPlan]);

  function patchUpdatedFields() {
    if (!dirtyFields) return;

    const fieldsToPatch = Object.keys(dirtyFields);
    let post = {};
    fieldsToPatch.forEach((key) => (post[key] = methods.getValues(key)));

    patchPlan(
      { planId, data: post },
      {
        onSuccess: (updatedData) => {
          methods.reset(updatedData, { keepValues: true });
        },
      }
    );
  }

  // Add a warning if user tries to close the page without saving
  useEffect(() => {
    if (isDirty && currentSubjectPlan?.id) {
      window.addEventListener("beforeunload", warnUser);
    } else {
      window.removeEventListener("beforeunload", warnUser);
    }

    function warnUser(e) {
      e.preventDefault();
      e.returnValue = "";
    }

    return () => window.removeEventListener("beforeunload", warnUser);
  }, [isDirty, currentSubjectPlan?.id]);

  // Change background color variables
  useEffect(() => {
    document.body.classList.add(styles.background);
    return () => document.body.classList.remove(styles.background);
  }, []);

  // Submit data to server
  function onSubmit(data) {
    if (isSubmitting) return;

    const formattedData = formatPlanForDatabase({
      ...currentSubjectPlan,
      ...data,
    });
    const post = planId ? { ...formattedData, id: +planId } : formattedData;

    if (!post.id && +post.time > 0) {
      post.activities = Array(+post.time)
        .fill(emptyActivity)
        .map((element, index) => ({ ...element, position: index }));
    }

    savePlan(post, {
      onSuccess: (response) => {
        if (!planId && response?.data?.id) goToEditPlan(response.data.id);
      },
    });
  }

  function goToEditPlan(id) {
    const url = `/temaplan/rediger/${id}`;
    history.push(url);
    window.scrollTo({ top: 0 });
  }

  function getDescriptionBySubject(section, subjectName) {
    const subjectCode = getCodeForSubjectName(subjectName)?.slice(0, 3) || "";

    const subjectCodesWithDescription = {
      concepts: ["nat", "mat"],
      challenges: ["saf", "nat", "nor", "eng", "mat"],
    };

    const subjectHasDescription =
      subjectCodesWithDescription[section]?.includes(subjectCode);

    const TextComponent = (code) => (
      <TranslatedText
        translationKey={`subjectPlan:descriptions.${section}-bySubject.${code}`}
      />
    );

    if (subjectHasDescription) return TextComponent(subjectCode);
  }

  function handleAddSubject() {
    methods.setValue("subject", subject + ";");
  }

  function handleAddGrade() {
    methods.setValue("grade", grade + ";");
  }

  function isSubjectAlreadySelected(subject) {
    return subjectsArray.some(
      (selectedSubject) => selectedSubject === subject.name
    );
  }

  function isGradeAlreadySelected(grade) {
    return gradesArray.some((selectedGrade) => selectedGrade === grade);
  }

  function getCodeForSubjectName(name) {
    return subjectOptions.find((item) => item.name === name)?.code;
  }

  function updateSubjectsArray(newValue, index) {
    const updatedSubjects = [...subjectsArray];
    if (!!newValue) {
      updatedSubjects.splice(index, 1, newValue);
    } else {
      updatedSubjects.splice(index, 1);
    }

    methods.setValue("subject", updatedSubjects.join(";"), {
      shouldDirty: true,
    });
  }

  function updateGradesArray(newValue, index) {
    const updatedGrades = [...gradesArray];

    if (!!newValue) {
      updatedGrades.splice(index, 1, newValue);
    } else {
      updatedGrades.splice(index, 1);
    }

    methods.setValue("grade", updatedGrades.join(";"), { shouldDirty: true });
  }

  return (
    <>
      <main className={styles.main}>
        <h1>{planId ? t("general.editPlan") : t("general.makeNewPlan")}</h1>

        <section className={styles.form__container}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className={styles.form}
            >
              <label htmlFor="title">{t("labels.planTitle")}</label>
              <input
                {...methods.register("title")}
                type="text"
                id="title"
                maxLength={250}
                spellCheck="false"
                required
              />

              <span className={styles.description}>
                {t("descriptions.planTitle")}
              </span>

              {gradesArray.map((selectedGrade, index) => (
                <div className={styles.gradeContainer} key={`grade-${index}`}>
                  <label htmlFor={`grade-${index}`}>{t("labels.grade")}</label>
                  <select
                    id={`grade-${index}`}
                    className={styles.fadeIn}
                    value={selectedGrade}
                    onChange={(event) =>
                      updateGradesArray(event.target.value, index)
                    }
                  >
                    <option value="">Velg trinn...</option>
                    {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                      (element) => (
                        <option
                          key={`gradeOption-${element}`}
                          value={String(element)}
                          disabled={isGradeAlreadySelected(element)}
                        >
                          {element}. trinn
                        </option>
                      )
                    )}
                  </select>
                  <ChevronDown className={styles.dropdownArrow} />
                </div>
              ))}

              {gradesArray.length < 5 ? (
                <button
                  type="button"
                  onClick={handleAddGrade}
                  className={`${styles.button} ${styles.button__addSubject}`}
                  title={t("general.addGrade")}
                >
                  {`+ ${t("general.addGrade")}`}
                </button>
              ) : null}

              {subjectsArray.map((selectedSubject, index) => (
                <div
                  className={styles.subjectContainer}
                  key={`subject-${index}`}
                >
                  <label htmlFor={`subject-${index}`}>
                    {t("labels.subject")}
                  </label>

                  <select
                    id={`subject-${index}`}
                    className={styles.fadeIn}
                    value={selectedSubject}
                    onChange={(event) =>
                      updateSubjectsArray(event.target.value, index)
                    }
                  >
                    <option value="">Velg fag...</option>
                    {subjectOptions.map((subject, index) => (
                      <option
                        key={`${subject.code}-${index}`}
                        value={subject.name}
                        disabled={isSubjectAlreadySelected(subject)}
                      >
                        {subject.name}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className={styles.dropdownArrow} />

                  {!!selectedSubject ? (
                    <span className={`${styles.description} ${styles.fadeIn}`}>
                      <TranslatedText
                        translationKey="subjectPlan:descriptions.subject"
                        values={{
                          link: `https://www.udir.no/lk20/${getCodeForSubjectName(
                            selectedSubject
                          )}`,
                          linkTitle: `læreplanen ${selectedSubject}`,
                        }}
                      />
                    </span>
                  ) : null}
                </div>
              ))}

              {subjectsArray.length < 5 ? (
                <button
                  type="button"
                  onClick={() => handleAddSubject()}
                  className={`${styles.button} ${styles.button__addSubject}`}
                  title="Legg til fag"
                >
                  {`+ ${t("general.addSubject")}`}
                </button>
              ) : null}

              <label htmlFor="curriculumBase">
                {t("labels.curriculumBase")}
              </label>
              <RichTextArea
                name="curriculumBase"
                minHeight={13}
                editor={richTextEditorInstance}
              />

              <span className={styles.description}>
                {t("descriptions.curriculumBase")}
              </span>

              <label htmlFor="goals">
                {t("labels.goals")}
                <span className={styles.subLabel}>{t("labels.goals-sub")}</span>
              </label>
              <ExpandingTextArea name="goals" id="goals" minHeight={13} />

              <span className={styles.description}>
                <TranslatedText translationKey="subjectPlan:descriptions.goals" />
              </span>

              <label htmlFor="reason">{t("labels.reason")}</label>
              <ExpandingTextArea name="reason" id="reason" minHeight={13} />
              <span className={styles.description}>
                <TranslatedText translationKey="subjectPlan:descriptions.reason" />
              </span>

              <label htmlFor="challenges">{t("labels.challenges")}</label>
              <ExpandingTextArea
                name="challenges"
                id="challenges"
                minHeight={13}
              />

              <span className={styles.description}>
                <TranslatedText translationKey="subjectPlan:descriptions.challenges_pre" />
                <br />
                <br />

                {subjectsArray.map((subject, index) =>
                  getDescriptionBySubject("challenges", subject) ? (
                    <Fragment key={`description-${subject}-${index}`}>
                      {getDescriptionBySubject("challenges", subject)}
                      <br />
                      <br />
                    </Fragment>
                  ) : null
                )}

                <TranslatedText translationKey="subjectPlan:descriptions.challenges" />
              </span>

              <label htmlFor="concepts">{t("labels.concepts")}</label>
              <ExpandingTextArea name="concepts" id="concepts" minHeight={7} />

              <span className={styles.description}>
                <TranslatedText translationKey="subjectPlan:descriptions.concepts" />
                <br />

                {subjectsArray.map((subject, index) =>
                  getDescriptionBySubject("concepts", subject) ? (
                    <p key={`concepts-${subject}-${index}`}>
                      {getDescriptionBySubject("concepts", subject)}
                    </p>
                  ) : null
                )}
              </span>

              <label htmlFor="time">{`${t("labels.time")} (${t(
                "general.sessions"
              )})`}</label>

              <span className={styles.numberInput__container}>
                <input
                  {...methods.register("time", { valueAsNumber: true })}
                  type="number"
                  id="time"
                  min="0"
                  className={styles.numberInput}
                />
              </span>
              <span className={styles.description}>
                <TranslatedText translationKey="subjectPlan:descriptions.time" />
              </span>

              {/* BUTTONS AREA */}
              <section className={styles.finalButtons__container}>
                {/* SAVE ERROR MESSAGE */}
                {isSavingError || isPatchingError ? (
                  <span className={styles.saveError}>
                    {t("common:savingError")}
                  </span>
                ) : null}

                {/* BUTTONS */}
                <section className={styles.saveButton__container}>
                  <button
                    type="submit"
                    className={`${styles.save} ${styles.button}`}
                    disabled={!isDirty}
                  >
                    {currentSubjectPlan
                      ? isSaving || isPatching
                        ? t("general.loading")
                        : !isDirty
                        ? t("general.saved")
                        : t("general.savePlan")
                      : isSaving || isPatching
                      ? t("general.loading")
                      : !isDirty
                      ? t("general.planCreated")
                      : t("general.createPlan")}
                  </button>

                  {!isDirty && !isSaving && !isPatching ? (
                    <span className={styles.saved}>
                      <IconCheckMark />
                      {t("general.saved")}!
                    </span>
                  ) : null}
                </section>

                {planId ? (
                  <button
                    type="button"
                    className={styles.button}
                    onClick={() => goToEditPlan(planId)}
                  >
                    <IconDocument />
                    <span>{t("general.toPlanDetails")}</span>
                  </button>
                ) : null}
              </section>
            </form>
          </FormProvider>
        </section>
      </main>
      <Footer />

      <Prompt
        when={!!isDirty && !!currentSubjectPlan?.id}
        message={t("common:confirmations.leaveWithoutSaving")}
      />
    </>
  );
};

export default NewPlan;
