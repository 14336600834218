export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : window.location.hostname.includes("localhost")
  ? "http://localhost:3000/"
  : "https://" + window.location.hostname + "/api/";

export const SOCKET_BASE_URL = API_BASE_URL + "socket/";
const socket = new URL(SOCKET_BASE_URL);
export const SOCKET_HOSTNAME_URL =
  socket.protocol +
  "//" +
  socket.hostname +
  (socket.port ? ":" + socket.port : "");
export const SOCKET_PATH = socket.pathname + "socket.io/";
