import { Toaster, toast, useToasterStore } from "react-hot-toast";
import { useEffect } from "react";
import CustomToast from "./CustomToast";

const TOAST_LIMIT = 1;

const NotificationContainer = () => {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.remove(t.id));
  }, [toasts]);

  return (
    <Toaster
      position="bottom-center"
      gutter={12}
      toastOptions={{ duration: Infinity }}
    >
      {(t) => <CustomToast toast={t} />}
    </Toaster>
  );
};

export default NotificationContainer;
