import { useMutation, useQueryClient } from "react-query";
import yearPlanAPI from "../../../api/yearPlanAPI";

export const useUpdateYearPlanAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(yearPlanAPI.updatePlanAccess, {
    onSuccess: () => queryClient.invalidateQueries("yearPlans"),
    onError: (error) => console.log("share access update error: ", error),
  });
};
