import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./Welcome.module.css";

import {
  doGetFeideLoginUrl,
  selectFeideUrl,
} from "../../features/auth/AuthSlice";

import {
  LogoForsknigsradet,
  LogoJaerskulen,
  LogoLesesenteret,
} from "../../common/Logos/Logos";

const Welcome = () => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const feideUrl = useSelector(selectFeideUrl);

  useEffect(() => {
    document.body.classList.add(styles.background);
    return () => document.body.classList.remove(styles.background);
  }, []);

  useEffect(() => {
    if (!feideUrl) dispatch(doGetFeideLoginUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feideUrl]);

  function handleLogInClick() {
    window.location = feideUrl;
  }

  return (
    <>
      {/* {isLoading ? <LoginSpinner /> : null} */}

      <main className={styles.main}>
        <h1 className={styles.title}>DigUp</h1>
        <h2 className={styles.subTitle}>{t("welcome.header")}</h2>
        <button
          className={styles.button}
          disabled={!feideUrl}
          onClick={handleLogInClick}
        >
          {t("welcome.logInWith")}{" "}
          <img
            src="/images/feide-logo.png"
            alt="Feide"
            width="92"
            height="32"
          ></img>
        </button>
      </main>
      <footer className={styles.footer}>
        <p>{t("welcome.subtext")}</p>

        <section className={styles.logos}>
          <LogoJaerskulen />
          <LogoLesesenteret />
          <LogoForsknigsradet />
        </section>
      </footer>
    </>
  );
};

export default Welcome;
