import { useQuery, useQueryClient } from "react-query";
import subjectPlanAPI from "../../../api/subjectPlanAPI";

export const useTakeSubjectPlanAccess = (token) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["access", token],
    () => subjectPlanAPI.takePlanAccess(token),
    {
      enabled: !!token,
      onSuccess: () => queryClient.invalidateQueries("plans"),
    }
  );
};
