import { useEffect } from "react";
import { addDays, areIntervalsOverlapping, formatISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import styles from "./EditSubjectPlanInYearPlanModal.module.css";

import { useSubjectPlans } from "../../../features/subjectPlan/hooks/useSubjectPlans";
import { useSaveSubjectPlanInYearPlan } from "../../../features/yearPlan/hooks/useSaveSubjectPlanInYearPlan";
import { useDeleteSubjectPlanInYearPlan } from "../../../features/yearPlan/hooks/useDeleteSubjectPlanInYearPlan";
import { useBlockBodyScrollOnMount } from "../../../common/hooks/useBlockBodyScrollOnMount";
import { ChevronDown, IconEye } from "../../../common/Icons/Icons";
import Spinner from "../Spinner/Spinner";

const EditSubjectPlanInYearPlanModal = ({
  yearPlan,
  selectedDate,
  planId,
  closeModal,
}) => {
  useBlockBodyScrollOnMount();
  const { t } = useTranslation("yearPlan");

  const { data: subjectPlans, isLoading } = useSubjectPlans();
  const { mutate: save, isLoading: isSaving } = useSaveSubjectPlanInYearPlan();
  const { mutate: deletePlan, isLoading: isDeleting } =
    useDeleteSubjectPlanInYearPlan();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    getValues,
  } = useForm();

  const selectedSubjectPlan = watch("subjectPlanId");

  useEffect(() => {
    if (!subjectPlans || !subjectPlans?.length) return;
    const plan = yearPlan.plans?.find((plan) => plan.id === planId);

    if (planId && plan) {
      const fromDate = new Date(plan.fromDate);
      const toDate = new Date(plan.toDate);

      reset({
        subjectPlanId: plan.planId,
        fromDate: formatISO(fromDate, { representation: "date" }),
        toDate: formatISO(toDate, { representation: "date" }),
      });
    } else {
      const fromDate = selectedDate || new Date(yearPlan.year, 7, 1);
      const toDate = selectedDate
        ? addDays(selectedDate, 4)
        : new Date(yearPlan.year, 7, 5);

      reset({
        subjectPlanId: subjectPlans[0].id,
        fromDate: formatISO(fromDate, {
          representation: "date",
        }),
        toDate: formatISO(toDate, {
          representation: "date",
        }),
      });
    }
  }, [subjectPlans, planId, yearPlan, selectedDate, reset]);

  function onSubmit(data) {
    const post = {
      fromDate: data.fromDate,
      toDate: data.toDate,
      planId: data.subjectPlanId,
    };

    if (planId) {
      post.id = planId;
    } else {
      post.yearPlanId = yearPlan.id;
    }

    save(post, { onSuccess: closeModal });
  }

  function removeSubjectPlan() {
    const decision = window.confirm(
      t("common:confirmations.removeSubjectPlan")
    );

    if (decision) deletePlan(planId, { onSuccess: closeModal });
  }

  function overlapsWithOtherPlans(fromDate, toDate) {
    for (const plan of yearPlan.plans?.filter((item) => !!item.plan)) {
      if (plan.id === planId) continue;
      if (
        areIntervalsOverlapping(
          { start: new Date(fromDate), end: new Date(toDate) },
          { start: new Date(plan.fromDate), end: new Date(plan.toDate) },
          { inclusive: true }
        )
      )
        return true;
    }

    return false;
  }

  return (
    <article className={styles.container}>
      <div
        className={styles.background}
        aria-hidden="true"
        onClick={closeModal}
      />

      <form className={styles.box} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.header}>
          {planId
            ? t("editSubjectPlanInYearPlan")
            : t("addSubjectPlanInYearPlan")}
        </h2>

        {isLoading ? (
          <article className={styles.spinner__container}>
            <Spinner />
          </article>
        ) : subjectPlans?.length ? (
          <>
            {!planId ? (
              <p className={styles.subTitle}>
                {t("ifNoPlanMessage1")}{" "}
                <NavLink to="/temaplan/ny" className={styles.subTitleLink}>
                  {t("ifNoPlanMessage2")}
                </NavLink>
              </p>
            ) : null}

            <section className={styles.select__container}>
              <label htmlFor="subjectPlanId">{t("chooseSubjectPlan")}</label>

              <select id="subjectPlanId" {...register("subjectPlanId")}>
                {subjectPlans?.map((item, index) => (
                  <option key={`${item.id}-${index}`} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              <ChevronDown className={styles.dropdownArrow} />
            </section>

            <NavLink
              to={
                selectedSubjectPlan
                  ? `/temaplan/visning/${selectedSubjectPlan}`
                  : "#"
              }
              className={styles.showSubjectPlanLink}
            >
              {selectedSubjectPlan ? (
                <>
                  <IconEye />
                  <span>{t("showSubjectPlan")}</span>
                </>
              ) : null}
            </NavLink>

            <section className={styles.dates__container}>
              <section className={styles.date}>
                <label htmlFor="fromDate">{t("from")}</label>
                <input
                  type="date"
                  id="fromDate"
                  aria-invalid={errors.fromDate ? "true" : "false"}
                  {...register("fromDate", {
                    required: true,
                    validate: {
                      beforeToDate: (value) =>
                        new Date(value) <= new Date(getValues("toDate")) ||
                        t("fromDateBeforeToDate"),
                      overlaps: (value) =>
                        !overlapsWithOtherPlans(value, getValues("toDate")) ||
                        t("subjectPlanDateTaken"),
                    },
                  })}
                  className={errors.fromDate ? styles.invalid : ""}
                />
              </section>

              <section className={styles.date}>
                <label htmlFor="toDate">{t("to")}</label>
                <input
                  type="date"
                  id="toDate"
                  aria-invalid={errors.toDate ? "true" : "false"}
                  {...register("toDate", {
                    required: true,
                  })}
                />
              </section>
            </section>

            {Object.keys(errors)?.map((key, index) => (
              <p key={`${key}-${index}`} className={styles.error} role="alert">
                {errors[key].message}
              </p>
            ))}

            <section className={styles.buttons__container}>
              <section className={styles.saveCancel}>
                <button
                  type="submit"
                  className={styles.save}
                  disabled={isSaving}
                >
                  {isSaving
                    ? t("loading")
                    : planId
                    ? t("save")
                    : t("addInYearPlan")}
                </button>

                <button
                  type="button"
                  className={styles.cancel}
                  onClick={closeModal}
                >
                  {t("cancel")}
                </button>
              </section>

              {planId && (
                <button
                  type="button"
                  className={styles.delete}
                  onClick={removeSubjectPlan}
                  disabled={isDeleting}
                >
                  {isDeleting ? t("loading") : t("removeSubjectPlan")}
                </button>
              )}
            </section>
          </>
        ) : (
          <p className={styles.createSubjectPlanMessage}>
            {t("createSubjectPlanToAddToYearPlan1")}{" "}
            <NavLink to="/temaplan/ny" className={styles.subTitleLink}>
              {t("createSubjectPlanToAddToYearPlan2")}.
            </NavLink>
          </p>
        )}
      </form>
    </article>
  );
};

export default EditSubjectPlanInYearPlanModal;
