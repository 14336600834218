import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import userAPI from "../../../api/userAPI";
import { selectAuthUserId } from "../../auth/AuthSlice";

export const useMyUser = () => {
  const userId = useSelector(selectAuthUserId);

  return useQuery(["user", "me"], userAPI.fetchMyUser, { enabled: !!userId });
};
