import { useMutation, useQueryClient } from "react-query";
import userAPI from "../../../api/userAPI";

export const usePatchUser = () => {
  const queryClient = useQueryClient();

  return useMutation(userAPI.patchMyUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
    onError: (error) => {
      console.log("Error while saving user: ", error);
    },
  });
};
