import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import styles from "./SearchSelect.module.css";

import { ChevronDown } from "../../common/Icons/Icons";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <ChevronDown />
  </components.DropdownIndicator>
);

const SearchSelect = ({ name, id, options, onChange, value }) => {
  const { t } = useTranslation("yearPlan");

  return (
    <section className={styles.container}>
      <label htmlFor={id} className={styles.label}>
        {name}
      </label>
      <Select
        id={id}
        name={id}
        menuPortalTarget={document.body}
        placeholder={"—"}
        noOptionsMessage={() => t("noYearPlansToShow")}
        options={options}
        onChange={onChange}
        value={value}
        className={styles.select}
        styles={{
          control: (provided) => ({
            ...provided,
            border:
              "var(--stroke-width-standard) solid var(--color-green-dark)",
            borderRadius: "0",
            background: "#fff",
            ":hover": {
              border:
                "var(--stroke-width-standard) solid var(--color-green-dark)",
            },
          }),
          menu: (provided) => ({
            ...provided,
            border: "none",
            borderRadius: "0",
            margin: "0",
            fontSize: "1.5rem",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#000",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "var(--color-green-dark)",
            padding: "0 0.7rem",
          }),
          clearIndicator: (provided) => ({
            ...provided,
            color: "var(--color-green-dark)",
            "> svg": {
              width: "1.6rem",
              height: "1.6rem",
            },
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: "var(--color-green-middle)",
          }),
        }}
        components={{ DropdownIndicator }}
        isClearable
      />
    </section>
  );
};

export default SearchSelect;
