import {
  eachDayOfInterval,
  getWeek,
  getISODay,
  isWithinInterval,
  isSameDay,
  isSameWeek,
  endOfMonth,
  startOfDay,
  endOfDay,
} from "date-fns";
import { useSelector } from "react-redux";
import styles from "./CompareCalendarWeek.module.css";

import { selectAuthUserId } from "../../features/auth/AuthSlice";
import { NavLink } from "react-router-dom";

const CompareCalendarWeek = ({
  weekInterval,
  yearPlan,
  addSubjectPlan,
  editSubjectPlan,
}) => {
  const days = eachDayOfInterval(weekInterval);
  const userId = useSelector(selectAuthUserId);

  const subjectPlans = yearPlan.plans
    ?.filter((item) => item.plan)
    ?.map((plan) => ({
      ...plan,
      interval: {
        start: startOfDay(new Date(plan.fromDate)),
        end: endOfDay(new Date(plan.toDate)),
      },
    }));

  const weekOptions = { weekStartsOn: 1, firstWeekContainsDate: 4 };

  const isLastWeekOfYear =
    getWeek(weekInterval.start, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    }) === 52;

  return (
    <section
      className={`${styles.container} ${
        isLastWeekOfYear ? styles.isLastWeekOfYear : ""
      }`}
    >
      {days.map((day) => {
        const dayOfWeek = getISODay(day);
        const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;

        const planForDay = subjectPlans.find((plan) =>
          isWithinInterval(day, plan.interval)
        );
        const hasPlan = Boolean(planForDay);

        const isFirstDayOfPlanThisWeek =
          !hasPlan || isWeekend
            ? false
            : isSameDay(day, planForDay.interval.start) || dayOfWeek === 1;

        const isLastDayOfMonth = isSameDay(day, endOfMonth(day));

        const isLastDayOfPlan =
          !hasPlan || isWeekend
            ? false
            : isSameDay(day, planForDay.interval.end);

        const shouldDisplayTitle = isFirstDayOfPlanThisWeek;

        const dayNumberForPlanEnd = hasPlan
          ? getISODay(planForDay.interval.end)
          : 0;

        const isSameWeekAsPlanEnd =
          hasPlan && isSameWeek(day, planForDay.interval.end, weekOptions);

        const indexOfPlan = subjectPlans.indexOf(planForDay);

        const daysUntilWeekend = 6 - dayOfWeek;
        const daysUntilEndOfPlan =
          (dayNumberForPlanEnd < 6 ? dayNumberForPlanEnd + 1 : 6) - dayOfWeek;

        const titleWidth = isSameWeekAsPlanEnd
          ? daysUntilEndOfPlan
          : daysUntilWeekend;

        const hasWriteAccessToYearPlan =
          yearPlan.userId === userId ||
          yearPlan.access.find((item) => item.userId === userId)?.access ===
            "W";

        const buttonTitle = hasWriteAccessToYearPlan
          ? hasPlan
            ? "Rediger temaplan i årsplan"
            : "Legg til temaplan i årsplanen"
          : "";

        function onDayClick() {
          if (isWeekend) return;

          if (hasWriteAccessToYearPlan) {
            if (hasPlan) {
              editSubjectPlan(yearPlan.id, planForDay.id);
            } else {
              addSubjectPlan(yearPlan.id, day);
            }
          }
        }

        const title = shouldDisplayTitle ? (
          <span
            className={styles.planTitle}
            style={{
              "--titleWidth": titleWidth,
            }}
          >
            {planForDay.plan.title}
          </span>
        ) : null;

        const planColor =
          (indexOfPlan + 1) % 3 === 0
            ? "#fb8f67"
            : (indexOfPlan + 1) % 2 === 0
            ? "#ffc2b4"
            : "#f8e16c";

        const classes = `
          ${styles.day}
          ${styles[`weekDay-${dayOfWeek}`] || ""}
          ${hasPlan ? styles.hasPlan : ""}
          ${isLastDayOfPlan ? styles.isLastDayOfPlan : ""}
          ${hasWriteAccessToYearPlan ? styles.hasWriteAccessToYearPlan : ""}
        `;

        if (hasPlan && !hasWriteAccessToYearPlan && !isWeekend) {
          return (
            <NavLink
              to={`/temaplan/visning/${planForDay.planId}`}
              key={`${day}-${yearPlan.id}`}
              className={classes}
              style={{
                "--planColor": planColor,
              }}
            >
              {title}
              {isLastDayOfMonth ? (
                <div className={styles.endOfMonthMarker} />
              ) : null}
            </NavLink>
          );
        } else {
          return (
            <button
              key={`${day}-${yearPlan.id}`}
              className={classes}
              style={{
                "--planColor": planColor,
              }}
              onClick={onDayClick}
              title={buttonTitle}
            >
              {title}
              {isLastDayOfMonth ? (
                <div className={styles.endOfMonthMarker} />
              ) : null}
            </button>
          );
        }
      })}
    </section>
  );
};

export default CompareCalendarWeek;
