import { endOfMonth } from "date-fns";
import styles from "./CalendarQuarter.module.css";
import CalendarMonth from "./CalendarMonth";

const CalendarQuarter = ({
  months,
  yearPlan,
  addSubjectPlan,
  editSubjectPlan,
}) => {
  return (
    <section className={styles.container}>
      {months.map((month, index) => (
        <CalendarMonth
          key={`${month}-${index}`}
          month={{
            start: month,
            end: endOfMonth(month),
          }}
          yearPlan={yearPlan}
          addSubjectPlan={addSubjectPlan}
          editSubjectPlan={editSubjectPlan}
        />
      ))}
    </section>
  );
};

export default CalendarQuarter;
