import { eachWeekOfInterval, endOfWeek, startOfWeek, setWeek } from "date-fns";
import styles from "./CalendarPlan.module.css";

import CompareCalendarWeek from "./CompareCalendarWeek";

const CalendarPlan = ({
  yearPlan,
  startWeekNumber,
  endWeekNumber,
  addSubjectPlan,
  editSubjectPlan,
}) => {
  const weekOptions = { weekStartsOn: 1, firstWeekContainsDate: 4 };

  const intervalStartForPlan = startOfWeek(
    setWeek(new Date(yearPlan?.year, 1, 1), startWeekNumber, weekOptions),
    weekOptions
  );

  const intervalEndForPlan = endOfWeek(
    setWeek(new Date(yearPlan?.year + 1, 1, 1), endWeekNumber, weekOptions),
    weekOptions
  );

  const timeInterval = { start: intervalStartForPlan, end: intervalEndForPlan };
  const weekIntervals = eachWeekOfInterval(timeInterval, weekOptions)?.map(
    (weekStart) => ({
      start: weekStart,
      end: endOfWeek(weekStart, weekOptions),
    })
  );

  return (
    <article className={styles.container}>
      {yearPlan
        ? weekIntervals.map((weekInterval, index) => (
            <CompareCalendarWeek
              key={`yearPlan-${yearPlan.id}-week-${
                weekInterval?.start || index
              }`}
              weekInterval={weekInterval}
              yearPlan={yearPlan}
              addSubjectPlan={addSubjectPlan}
              editSubjectPlan={editSubjectPlan}
            />
          ))
        : null}
    </article>
  );
};

export default CalendarPlan;
