import { useEffect } from "react";

// Blocks scroll on body component on mount and unblocks on unmount
export const useBlockBodyScrollOnMount = () =>
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);
