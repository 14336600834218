import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAPI from "../../api/authAPI";
import { setBearer } from "../../api/fetch";

let refreshInterval = null;

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const doLogout = createAsyncThunk("auth/logoutStatus", async () => {
  await authAPI.feideLogout();
  window.location.href = "/";
});

export const doGetFeideLoginUrl = createAsyncThunk(
  "auth/feideLogin",
  async (_, { rejectWithValue }) => {
    try {
      return await authAPI.getFeideLoginUrl();
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const doVerifyFeideCode = createAsyncThunk(
  "auth/verifyFeideCode",
  async (code, { rejectWithValue }) => {
    try {
      return await authAPI.verifyFeideCode(code);
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const initialState = {
  jwt: null,
  jwtTimestamp: null,
  isLoggingIn: false,
  loginError: null,
  feideLoginUrl: null,
  user: {
    id: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: {
    [doLogout.fulfilled]: (state) => {
      state.jwt = null;
      state.user = { id: null };
    },
    [doGetFeideLoginUrl.rejected]: (state, { payload }) => {
      state.loginError = payload;
    },
    [doGetFeideLoginUrl.fulfilled]: (state, { payload }) => {
      state.feideLoginUrl = payload?.data;
    },
    [doVerifyFeideCode.pending]: (state, { payload }) => {
      state.isLoggingIn = true;
      state.loginError = null;
    },
    [doVerifyFeideCode.rejected]: (state, { payload }) => {
      state.isLoggingIn = false;
      state.loginError = payload;
    },
    [doVerifyFeideCode.fulfilled]: (state, { payload }) => {
      state.jwt = payload.data.access_token;
      state.jwtTimestamp = Date.now();
      setBearer(state.jwt);
      const data = parseJwt(state.jwt);
      state.user.id = data.sub;
      state.isLoggingIn = false;
    },
  },
});

export const selectLoginToken = (state) => state.auth.jwt;
export const selectAuthUserId = (state) => state.auth.user.id;
export const selectAuth = (state) => state.auth;
export const selectFeideUrl = (state) => state.auth.feideLoginUrl;
export const selectJwtTimestamp = (state) => state.auth.jwtTimestamp;

export const doJwtRefresh = () => async (dispatch) => {
  try {
    const data = await authAPI.refresh();
    dispatch(doVerifyFeideCode.fulfilled(data));
  } catch (err) {
    dispatch(doVerifyFeideCode.rejected());
  }
};

export const keepRefreshingJwt = () => (dispatch) => {
  const shouldDoJwtRefresh =
    window.location.href.indexOf("oauth_callback") === -1;
  if (shouldDoJwtRefresh) {
    dispatch(doJwtRefresh());
  }

  if (refreshInterval) clearInterval(refreshInterval);
  refreshInterval = setInterval(async () => {
    dispatch(doJwtRefresh());
  }, 2 * 60 * 1000);
};

export default authSlice.reducer;
